import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    isAdmin,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    handleApprove,
    handleReject,
    sendAuthenticationApprovalInProgress,
    sendAuthenticationApprovalSuccess,
    sendAuthenticationApprovalError,
    sendAuthenticationRejectionInProgress,
    sendAuthenticationRejectionSuccess,
    sendAuthenticationRejectionError,
    sendAuthenticationInReviewInProgress,
    sendAuthenticationInReviewSuccess,
    sendAuthenticationInReviewError,
    sendProductApprovalInProgress,
    sendProductApprovalSuccess,
    sendProductApprovalError,
    sendProductRejectionInProgress,
    sendProductRejectionSuccess,
    sendProductRejectionError,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe
        isOwnListing={isOwnListing}
        isAdmin={isAdmin}
        listing={listing}
        editParams={editParams}
        handleApprove={handleApprove}
        handleReject={handleReject}
        sendAuthenticationApprovalInProgress={sendAuthenticationApprovalInProgress}
        sendAuthenticationApprovalSuccess={sendAuthenticationApprovalSuccess}
        sendAuthenticationApprovalError={sendAuthenticationApprovalError}
        sendAuthenticationRejectionInProgress={sendAuthenticationRejectionInProgress}
        sendAuthenticationRejectionSuccess={sendAuthenticationRejectionSuccess}
        sendAuthenticationRejectionError={sendAuthenticationRejectionError}
        sendAuthenticationInReviewInProgress={sendAuthenticationInReviewInProgress}
        sendAuthenticationInReviewSuccess={sendAuthenticationInReviewSuccess}
        sendAuthenticationInReviewError={sendAuthenticationInReviewError}
        sendProductApprovalInProgress={sendProductApprovalInProgress}
        sendProductApprovalSuccess={sendProductApprovalSuccess}
        sendProductApprovalError={sendProductApprovalError}
        sendProductRejectionInProgress={sendProductRejectionInProgress}
        sendProductRejectionSuccess={sendProductRejectionSuccess}
        sendProductRejectionError={sendProductRejectionError}
      />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          />
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
