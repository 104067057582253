import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { EditCouponForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

import {
  updateProfile,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './EditCouponPage.css';

const NEW_COUPON_CODE_INDICATOR = '000000000000000000000000000000';

export class EditCouponPageComponent extends Component {
  render() {
    const {
      params = {},
      currentUser,
      updateInProgress,
      updateProfileError,
      scrollingDisabled,
      intl,
      history,
      onUpdateProfile,
    } = this.props;
    const { code } = params;

    const coupons = currentUser?.attributes?.profile?.publicData?.coupons || {};
    const isExistingCode =
      typeof code === 'string' && code.length > 0 && code !== NEW_COUPON_CODE_INDICATOR;
    const coupon = isExistingCode && coupons[ code ] ? coupons[ code ] : {};
    const { title, description, percentage, fixed_amount } = coupon;

    const handleSubmit = values => {
      const { coupon_code, title, description, percentage, fixed_amount } = values;

      coupons[ coupon_code ] = {
        title,
        description,
        percentage: typeof percentage === 'string' && percentage.match(/\d+/) ?
          Number.parseInt(percentage) : typeof percentage === 'number' ?
            percentage : null,
        fixed_amount: typeof fixed_amount === 'string' && fixed_amount.match(/\d+/) ?
          Number.parseInt(fixed_amount) : typeof fixed_amount === 'number' ?
            fixed_amount : null,
        created_at: isExistingCode ? coupons[ code ].created_at : Date.now()
      };

      onUpdateProfile({ publicData: { coupons }}).then(() => {
        const routes = routeConfiguration();

        // Redirect to ManageCouponsPage
        this.props.history.push( createResourceLocatorString('ManageCouponsPage', routes, {}, {}));
      })
    };

    const editCouponForm = currentUser?.id ? (
      <EditCouponForm
        className={css.form}
        history={history}
        coupons={coupons}
        isExistingCode={isExistingCode}
        initialValues={{
          coupon_code: code !== NEW_COUPON_CODE_INDICATOR ? code : null,
          title, description, percentage, fixed_amount
        }}
        updateInProgress={updateInProgress}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
      />
    ) : null;

    const pageTitle = intl.formatMessage({
      id: isExistingCode ? 'EditCouponPage.existingCouponTitle' : 'EditCouponPage.newCouponTitle'
    });

    return (
      <Page className={css.root} title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="EditCouponPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="EditCouponPage.heading" />
                </h1>
              </div>
              {editCouponForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

EditCouponPageComponent.defaultProps = {
  updateProfileError: null,
};

const { bool, func } = PropTypes;

EditCouponPageComponent.propTypes = {
  onUpdateProfile: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;

  return {
    currentUser,
    updateInProgress,
    updateProfileError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: actionPayload => dispatch(updateProfile(actionPayload)),
});

const EditCouponPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(EditCouponPageComponent);

export default EditCouponPage;
