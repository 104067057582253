import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import capitalize from 'lodash.capitalize';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { required } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Form, IconSpinner, PrimaryButton, FieldDateRangeInput, FieldSelect,
         FieldTextInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const startDate = moment(0, "HH").add(2, 'days').toDate();
    const endDate = moment(0, "HH").add(3, 'days').toDate();

    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(values) {
    const startDate = moment(0, "HH").add(2, 'days').toDate();
    const endDate = moment(0, "HH").add(3, 'days').toDate();
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const { couponCode } = values.values;

    const coupons = this.props.mainAdminUser?.attributes?.profile?.publicData?.coupons || {};
    const coupon = coupons && couponCode ? coupons[ couponCode ] : null;

    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate },
        listingId,
        isOwnListing,
        couponCode: coupon ? couponCode : null
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            values,
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            listing,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            mainAdminUser,
          } = fieldRenderProps;

          const coupons = mainAdminUser?.attributes?.profile?.publicData?.coupons || {};
          const coupon = coupons && values.couponCode ? coupons[ values.couponCode ] : null;

          const couponError = values.couponCode && !coupon ? (
            <p className={css.error}>
              <FormattedMessage id="BookingDatesForm.couponCodeError" />
            </p>
          ) : null;

          const startDate = moment(0, "HH").add(2, 'days').toDate();
          const endDate = moment(0, "HH").add(3, 'days').toDate();

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const deliveryLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryTitle',
          });
          const requiredDeliveryMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDelivery',
          });
          const deliveryPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryPlaceholder',
          });
          const deliveryOptions =
            listing && listing.attributes.publicData && listing.attributes.publicData.delivery || [];

          // Delivery address fields

          const contactEmailLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryContactEmailLabel',
          });
          const contactEmailPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryContactEmailPlaceholder',
          });
          const contactEmailRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredContactEmail',
          });

          const contactPhoneLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryContactPhoneLabel',
          });
          const contactPhonePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryContactPhonePlaceholder',
          });

          const companyNameLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCompanyNameLabel',
          });
          const companyNamePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCompanyNamePlaceholder',
          });

          const recipientNameLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryRecipientNameLabel',
          });
          const recipientNamePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryRecipientNamePlaceholder',
          });
          const recipientNameRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredRecipientName',
          });

          const countryLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCountryLabel',
          });
          const countryPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCountryPlaceholder',
          });
          const countryRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredCountry',
          });

          const stateLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryStateLabel',
          });
          const statePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryStatePlaceholder',
          });
          const stateRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredState',
          });

          const postalCodeLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryPostalCodeLabel',
          });
          const postalCodePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryPostalCodePlaceholder',
          });
          const postalCodeRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredPostalCode',
          });

          const cityLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCityLabel',
          });
          const cityPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryCityPlaceholder',
          });
          const cityRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredCity',
          });

          const streetLabel = intl.formatMessage({
            id: 'BookingDatesForm.deliveryStreetLabel',
          });
          const streetPlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.deliveryStreetPlaceholder',
          });
          const streetRequired = intl.formatMessage({
            id: 'BookingDatesForm.requiredStreet',
          });

          const couponCodeLabel = intl.formatMessage({
            id: 'BookingDatesForm.couponCodeLabel',
          });
          const couponCodePlaceholder = intl.formatMessage({
            id: 'BookingDatesForm.couponCodePlaceholder',
          });

          const deliveryAddressFields = values.deliveryMethod === 'shipping' ? (
            <>
              <FieldTextInput
                id="recipientName"
                name="recipientName"
                className={css.deliveryAddressField}
                type="text"
                label={recipientNameLabel}
                placeholder={recipientNamePlaceholder}
                validate={required(recipientNameRequired)}
                autoComplete="name"
              />
              <FieldTextInput
                id="companyName"
                name="companyName"
                className={css.deliveryAddressField}
                type="text"
                label={companyNameLabel}
                placeholder={companyNamePlaceholder}
                autoComplete="organization"
              />
              <FieldTextInput
                id="contactEMail"
                name="contactEMail"
                className={css.deliveryAddressField}
                type="text"
                label={contactEmailLabel}
                placeholder={contactEmailPlaceholder}
                validate={required(contactEmailRequired)}
                autoComplete="email"
              />
              <FieldTextInput
                id="contactPhone"
                name="contactPhone"
                className={css.deliveryAddressField}
                type="text"
                label={contactPhoneLabel}
                placeholder={contactPhonePlaceholder}
                autoComplete="tel"
              />
              <FieldTextInput
                id="country"
                name="country"
                className={css.deliveryAddressField}
                type="text"
                label={countryLabel}
                placeholder={countryPlaceholder}
                validate={required(countryRequired)}
                autoComplete="country-name"
              />
              <FieldTextInput
                id="state"
                name="state"
                className={css.deliveryAddressField}
                type="text"
                label={stateLabel}
                placeholder={statePlaceholder}
                validate={required(stateRequired)}
                autoComplete="address-level1"
              />
              <FieldTextInput
                id="postalCode"
                name="postalCode"
                className={css.deliveryAddressField}
                type="text"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
                validate={required(postalCodeRequired)}
                autoComplete="postal-code"
              />
              <FieldTextInput
                id="city"
                name="city"
                className={css.deliveryAddressField}
                type="text"
                label={cityLabel}
                placeholder={cityPlaceholder}
                validate={required(cityRequired)}
                autoComplete="address-level2"
              />
              <FieldTextInput
                id="street"
                name="street"
                className={css.deliveryAddressField}
                type="text"
                label={streetLabel}
                placeholder={streetPlaceholder}
                validate={required(streetRequired)}
                autoComplete="street-address"
              />
            </>
          ) : null;


          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <FieldSelect
                className={css.deliveryOptions}
                name='deliveryMethod'
                id='deliveryMethod'
                label={deliveryLabel}
                validate={required(requiredDeliveryMessage)}
              >
                <option disabled value="">
                  {deliveryPlaceholder}
                </option>
                {deliveryOptions.map(c => (
                  <option key={c} value={c}>
                    {capitalize(c)}
                  </option>
                ))}
              </FieldSelect>
              <FieldTextInput
                id="couponCode"
                name="couponCode"
                className={css.couponCodeField}
                type="text"
                label={couponCodeLabel}
                placeholder={couponCodePlaceholder}
              />
              {couponError}

              {deliveryAddressFields}

              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                useMobileMargins
                disabled={fetchLineItemsInProgress}
              />

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
