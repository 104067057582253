import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SEARCH_AUTHENTICATION_REQUEST = 'app/SearchPage/SEARCH_AUTHENTICATION_REQUEST';
export const SEARCH_AUTHENTICATION_SUCCESS = 'app/SearchPage/SEARCH_AUTHENTICATION_SUCCESS';
export const SEARCH_AUTHENTICATION_ERROR = 'app/SearchPage/SEARCH_AUTHENTICATION_ERROR';

export const STATUS_SUBSCRIBE_REQUEST = 'app/SearchPage/STATUS_SUBSCRIBE_REQUEST';
export const STATUS_SUBSCRIBE_SUCCESS = 'app/SearchPage/STATUS_SUBSCRIBE_SUCCESS';
export const STATUS_SUBSCRIBE_ERROR = 'app/SearchPage/STATUS_SUBSCRIBE_ERROR';

export const STATUS_UNSUBSCRIBE_REQUEST = 'app/SearchPage/STATUS_UNSUBSCRIBE_REQUEST';
export const STATUS_UNSUBSCRIBE_SUCCESS = 'app/SearchPage/STATUS_UNSUBSCRIBE_SUCCESS';
export const STATUS_UNSUBSCRIBE_ERROR = 'app/SearchPage/STATUS_UNSUBSCRIBE_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  searchComplete: false,
  searchError: null,
  authenticationListing: null,
  subscribeInProgress: false,
  subscribeError: null,
  unsubscribeInProgress: false,
  unsubscribeError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_AUTHENTICATION_REQUEST:
      return {
        ...state,
        authenticationListing: null,
        searchInProgress: true,
        searchComplete: false,
        searchError: null,
      };
    case SEARCH_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authenticationListing: payload.data.data[0],
        searchInProgress: false,
        searchComplete: true,
      };
    case SEARCH_AUTHENTICATION_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchComplete: true,
        searchError: payload
      };

    case STATUS_SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscribeInProgress: true,
        subscribeError: null,
      };
    case STATUS_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribeInProgress: false,
      };
    case STATUS_SUBSCRIBE_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        subscribeInProgress: false,
        subscribeError: payload
      };

    case STATUS_UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        unsubscribeInProgress: true,
        unsubscribeError: null,
      };
    case STATUS_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribeInProgress: false,
      };
    case STATUS_UNSUBSCRIBE_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        unsubscribeInProgress: false,
        unsubscribeError: payload
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchAuthenticationRequest = () => ({
  type: SEARCH_AUTHENTICATION_REQUEST,
  payload: {},
});

export const searchAuthenticationSuccess = response => ({
  type: SEARCH_AUTHENTICATION_SUCCESS,
  payload: { data: response.data },
});

export const searchAuthenticationError = e => ({
  type: SEARCH_AUTHENTICATION_ERROR,
  error: true,
  payload: e,
});

export const statusSubscribeRequest = () => ({
  type: STATUS_SUBSCRIBE_REQUEST,
  payload: {},
});

export const statusSubscribeSuccess = () => ({
  type: STATUS_SUBSCRIBE_SUCCESS,
  payload: {},
});

export const statusSubscribeError = e => ({
  type: STATUS_SUBSCRIBE_ERROR,
  error: true,
  payload: e,
});

export const statusUnsubscribeRequest = () => ({
  type: STATUS_UNSUBSCRIBE_REQUEST,
  payload: {},
});

export const statusUnsubscribeSuccess = () => ({
  type: STATUS_UNSUBSCRIBE_SUCCESS,
  payload: {},
});

export const statusUnsubscribeError = e => ({
  type: STATUS_UNSUBSCRIBE_ERROR,
  error: true,
  payload: e,
});

export const searchAuthentication = referenceNumber => (dispatch, getState, sdk) => {
  dispatch(searchAuthenticationRequest());

  return sdk.listings
    .query({ pub_referenceNumber: referenceNumber.toString()})
    .then(response => {
      if(Array.isArray(response.data.data) && response.data.data.length === 1) {
        dispatch(searchAuthenticationSuccess(response));
      } else {
        dispatch(searchAuthenticationSuccess({ data: { data: [ null ]}}));
      }

      return response;
    })
    .catch(e => {
      dispatch(searchAuthenticationError(storableError(e)));
      throw e;
    });
};

export const statusChangeSubscribe = ( authenticationListingId, subscriberIds ) =>
  (dispatch, getState, sdk) =>
{
  dispatch(statusSubscribeRequest());

  console.log('statusChangeSubscribe');

  return sdk.ownListings
    .update(
      {
        id: new UUID(authenticationListingId),
        publicData: { subscribers: subscriberIds }
      }, { expand: true }
    )
    .then(response => {
      return dispatch(searchAuthentication(response.data.data.attributes.publicData.referenceNumber));
    })
    .then(() => {
      return dispatch(statusSubscribeSuccess());
    })
    .catch(e => {
      dispatch(statusSubscribeError(storableError(e)));
      throw e;
    });
};

export const statusChangeUnsubscribe = ( authenticationListingId, subscriberIds ) =>
  (dispatch, getState, sdk) =>
{
  dispatch(statusUnsubscribeRequest());

  return sdk.ownListings
    .update(
      {
        id: new UUID(authenticationListingId),
        publicData: { subscribers: subscriberIds }
      }, { expand: true }
    )
    .then(response => {
      return dispatch(searchAuthentication(response.data.data.attributes.publicData.referenceNumber));
    })
    .then(() => {
      return dispatch(statusUnsubscribeSuccess());
    })
    .catch(e => {
      dispatch(statusUnsubscribeError(storableError(e)));
      throw e;
    });
};
