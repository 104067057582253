import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink, ExternalLink } from '../../components';

import css from './SectionHowItWorks.css';

import shareImage from './images/share-icon.png';

const SectionHowItWorks = props => {
  const { rootClassName, className, onAuthenticate } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLine" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>
      </div>

      <div className={css.createListingLink}>
        <ExternalLink
          key="seeReviewsLink"
          href={'http://authentication.resaleforce.com/'}
          title={'See customer reviews'}
        >
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </ExternalLink>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
