import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './RequestDemoForm.css';

const RequestDemoFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        demoRequestError,
        demoRequestInProgress,
      } = fieldRenderProps;

      const fullNameLabel = intl.formatMessage({
        id: 'RequestDemoForm.fullNameLabel'
      });
      const fullNamePlaceholder = intl.formatMessage({
        id: 'RequestDemoForm.fullNamePlaceholder'
      });
      const fullNameRequiredMessage = intl.formatMessage({
        id: 'RequestDemoForm.fullNameRequired'
      });
      const fullNameRequired = validators.requiredAndNonEmptyString(fullNameRequiredMessage);

      const companyNameLabel = intl.formatMessage({
        id: 'RequestDemoForm.companyNameLabel'
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'RequestDemoForm.companyNamePlaceholder'
      });

      const companyEmailLabel = intl.formatMessage({
        id: 'RequestDemoForm.companyEmailLabel'
      });
      const companyEmailPlaceholder = intl.formatMessage({
        id: 'RequestDemoForm.companyEmailPlaceholder'
      });
      const companyEmailRequiredMessage = intl.formatMessage({
        id: 'RequestDemoForm.companyEmailRequired'
      });
      const companyEmailRequired = validators.emailFormatValid(companyEmailRequiredMessage);

      const phoneNumberLabel = intl.formatMessage({
        id: 'RequestDemoForm.phoneNumberLabel'
      });
      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'RequestDemoForm.phoneNumberPlaceholder'
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = demoRequestInProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="RequestDemoForm.heading" />
          </h2>
          <FieldTextInput
            className={css.field}
            type="text"
            name="fullName"
            id={formId ? `${formId}.fullName` : 'fullName'}
            label={fullNameLabel}
            placeholder={fullNamePlaceholder}
            validate={fullNameRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="companyName"
            id={formId ? `${formId}.companyName` : 'companyName'}
            label={companyNameLabel}
            placeholder={companyNamePlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="email"
            name="companyEmail"
            id={formId ? `${formId}.companyEmail` : 'companyEmail'}
            autocomplete="email"
            label={companyEmailLabel}
            placeholder={companyEmailPlaceholder}
            validate={companyEmailRequired}
          />
          <FieldTextInput
            className={css.field}
            type="text"
            name="phoneNumber"
            id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
            label={phoneNumberLabel}
            placeholder={phoneNumberPlaceholder}
          />

          <div className={submitButtonWrapperClassName}>
            {demoRequestError ? (
              <p className={css.error}>
                <FormattedMessage id="RequestDemoForm.sendRequestDemoError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="RequestDemoForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

RequestDemoFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

RequestDemoFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const RequestDemoForm = compose(injectIntl)(RequestDemoFormComponent);

RequestDemoForm.displayName = 'RequestDemoForm';

export default RequestDemoForm;
