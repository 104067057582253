import axios from 'axios';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import qs from 'qs';
import { apiBaseUrl } from '../../util/api';

// ================ Action types ================ //

export const INITIATE_SHIPPO_INTEGRATION_REQUEST = 'app/ShippingDetailsPage/INITIATE_SHIPPO_INTEGRATION_REQUEST';
export const INITIATE_SHIPPO_INTEGRATION_SUCCESS = 'app/ShippingDetailsPage/INITIATE_SHIPPO_INTEGRATION_SUCCESS';
export const INITIATE_SHIPPO_INTEGRATION_ERROR = 'app/ShippingDetailsPage/INITIATE_SHIPPO_INTEGRATION_ERROR';
export const FINALIZE_SHIPPO_INTEGRATION_REQUEST = 'app/ShippingDetailsPage/FINALIZE_SHIPPO_INTEGRATION_REQUEST';
export const FINALIZE_SHIPPO_INTEGRATION_SUCCESS = 'app/ShippingDetailsPage/FINALIZE_SHIPPO_INTEGRATION_SUCCESS';
export const FINALIZE_SHIPPO_INTEGRATION_ERROR = 'app/ShippingDetailsPage/FINALIZE_SHIPPO_INTEGRATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  shippoIntegrationError: null,
  shippoIntegrationInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_SHIPPO_INTEGRATION_REQUEST:
    case FINALIZE_SHIPPO_INTEGRATION_REQUEST:
      return {
        ...state,
        shippoIntegrationInProgress: true,
        shippoIntegrationError: null,
      };
    case INITIATE_SHIPPO_INTEGRATION_SUCCESS:
    case FINALIZE_SHIPPO_INTEGRATION_SUCCESS:
      return { ...state, shippoIntegrationInProgress: false };
    case INITIATE_SHIPPO_INTEGRATION_ERROR:
    case FINALIZE_SHIPPO_INTEGRATION_ERROR:
      return {
        ...state,
        shippoIntegrationInProgress: false,
        shippoIntegrationError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const initiateShippoIntegrationRequest = () => ({ type: INITIATE_SHIPPO_INTEGRATION_REQUEST });
export const initiateShippoIntegrationSuccess = () => ({ type: INITIATE_SHIPPO_INTEGRATION_SUCCESS });
export const initiateShippoIntegrationError = error => ({
  type: INITIATE_SHIPPO_INTEGRATION_ERROR,
  payload: error,
});
export const finalizeShippoIntegrationRequest = () => ({ type: FINALIZE_SHIPPO_INTEGRATION_REQUEST });
export const finalizeShippoIntegrationSuccess = () => ({ type: FINALIZE_SHIPPO_INTEGRATION_SUCCESS });
export const finalizeShippoIntegrationError = error => ({
  type: FINALIZE_SHIPPO_INTEGRATION_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const initiateShippoIntegration = shippoIntegrationState => (dispatch, getState, sdk) => {
  dispatch(initiateShippoIntegrationRequest());

  return sdk.currentUser
    .updateProfile(
      { privateData: { shippoIntegrationState } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      dispatch(initiateShippoIntegrationSuccess());

      return currentUser;
    })
    .catch(e => {
      console.error('Error initiating Shippo integration procedure: ' + e.message);
      dispatch(initiateShippoIntegrationError(storableError(e)));
    });
};

export const finalizeShippoIntegration = shippoCode => (dispatch, getState, sdk) => {
  dispatch(finalizeShippoIntegrationRequest());

  const data = { shippoCode };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/get-shippo-access-token`,
  };

  return axios(options)
    .then(response => {
      return sdk.currentUser.updateProfile(
        { privateData: { shippoAccessToken: response.data } },
        {
          expand: true,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        });
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      dispatch(finalizeShippoIntegrationSuccess());

      return dispatch(fetchCurrentUser());
    })
    .catch(e => {
      console.error('Error finalizing Shippo integration procedure: ' + e.message);
      dispatch(finalizeShippoIntegrationError(storableError(e)));
    });
};
