import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from './TopbarContainer';
import SectionSearch from './SectionSearch';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './AuthenticationTrackingPage.css';

import { searchAuthentication, statusChangeSubscribe, statusChangeUnsubscribe }
  from './AuthenticationTrackingPage.duck';
import { parse } from '../../util/urlHelpers';

export const AuthenticationTrackingPageComponent = props => {
  const {
    history,
    intl,
    location,
    currentUser,
    scrollingDisabled,
    authenticationListing,
    searchInProgress,
    searchComplete,
    searchError,
    onSearchAuthentication,
    onStatusChangeSubscribe,
    onStatusChangeUnsubscribe,
    subscribeInProgress,
    subscribeError,
    unsubscribeInProgress,
    unsubscribeError,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const { search } = location;
  const searchParams = parse(search);
  const { referenceNumber } = searchParams;

  const handleSubmit = values => {
    const { referenceNumber } = values;

    onSearchAuthentication(referenceNumber);
  };

  useEffect(() => {
    if(referenceNumber && !searchComplete){
      onSearchAuthentication(referenceNumber);
    }
  });

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionSearch
                  currentUser={currentUser}
                  initialSearchFormValues={{}}
                  authenticationListing={authenticationListing}
                  startReferenceNumber={referenceNumber}
                  searchInProgress={searchInProgress}
                  searchComplete={searchComplete}
                  searchError={searchError}
                  onSearchSubmit={handleSubmit}
                  onStatusChangeSubscribe={onStatusChangeSubscribe}
                  onStatusChangeUnsubscribe={onStatusChangeUnsubscribe}
                  subscribeInProgress={subscribeInProgress}
                  subscribeError={subscribeError}
                  unsubscribeInProgress={unsubscribeInProgress}
                  unsubscribeError={unsubscribeError}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

AuthenticationTrackingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    authenticationListing,
    searchInProgress,
    searchComplete,
    searchError,
    subscribeInProgress,
    subscribeError,
    unsubscribeInProgress,
    unsubscribeError,
  } = state.AuthenticationTrackingPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    authenticationListing,
    searchInProgress,
    searchComplete,
    searchError,
    subscribeInProgress,
    subscribeError,
    unsubscribeInProgress,
    unsubscribeError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearchAuthentication: referenceNumber => dispatch(searchAuthentication(referenceNumber)),
  onStatusChangeSubscribe: ( authenticationListingId, subscriberIds ) =>
    dispatch(statusChangeSubscribe( authenticationListingId, subscriberIds )),
  onStatusChangeUnsubscribe: ( authenticationListingId, subscriberIds ) =>
    dispatch(statusChangeUnsubscribe( authenticationListingId, subscriberIds )),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationTrackingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationTrackingPageComponent);

export default AuthenticationTrackingPage;
