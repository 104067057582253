import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { ShippingDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { initiateShippoIntegration } from './ShippingDetailsPage.duck';
import css from './ShippingDetailsPage.css';

const submitInitiateShippoIntegration = (initUserFunc) => {
  const shippoState = uuidv4();
  const clientId = process.env.REACT_APP_SHIPPO_CLIENT_ID;

  initUserFunc(shippoState).then(() => {
    window.location.assign(
      `https://goshippo.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=*&state=${shippoState}`
    );
  });
};

export const ShippingDetailsPageComponent = props => {
  const {
    shippoIntegrationError,
    shippoIntegrationInProgress,
    currentUser,
    scrollingDisabled,
    onInitiateShippoIntegration,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const shippoAccessToken = privateData.shippoAccessToken;
  const shippingForm = !shippoAccessToken ? (
    <ShippingDetailsForm
      className={css.form}
      shippoIntegrationError={shippoIntegrationError}
      currentUser={currentUser}
      onSubmit={() => submitInitiateShippoIntegration(onInitiateShippoIntegration)}
      inProgress={shippoIntegrationInProgress}
      ready={true}
    />
  ) : (
    <>
      You already passed the procedure of integration with Shippo and can further
      control your shipments via Shippo Dashboard.
    </>
  );

  const title = intl.formatMessage({ id: 'ShippingDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ShippingDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ShippingDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="ShippingDetailsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ShippingDetailsPage.heading" />
            </h1>
            {shippingForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ShippingDetailsPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

ShippingDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser} = state.user;
  const {
    shippoIntegrationError,
    shippoIntegrationInProgress,
  } = state.ShippingDetailsPage;
  return {
    shippoIntegrationError,
    shippoIntegrationInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onInitiateShippoIntegration: (shippoIntegrationState) => dispatch(initiateShippoIntegration(shippoIntegrationState)),
});

const ShippingDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShippingDetailsPageComponent);

ShippingDetailsPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default ShippingDetailsPage;
