import pick from 'lodash/pick';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { apiBaseUrl } from '../../util/api';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchMainAdminUser } from '../../ducks/user.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_AUTHENTICATION_APPROVAL_REQUEST = 'app/SendInvitePage/SEND_AUTHENTICATION_APPROVAL_REQUEST';
export const SEND_AUTHENTICATION_APPROVAL_SUCCESS = 'app/SendInvitePage/SEND_AUTHENTICATION_APPROVAL_SUCCESS';
export const SEND_AUTHENTICATION_APPROVAL_ERROR = 'app/SendInvitePage/SEND_AUTHENTICATION_APPROVAL_ERROR';

export const SEND_AUTHENTICATION_REJECTION_REQUEST = 'app/SendInvitePage/SEND_AUTHENTICATION_REJECTION_REQUEST';
export const SEND_AUTHENTICATION_REJECTION_SUCCESS = 'app/SendInvitePage/SEND_AUTHENTICATION_REJECTION_SUCCESS';
export const SEND_AUTHENTICATION_REJECTION_ERROR = 'app/SendInvitePage/SEND_AUTHENTICATION_REJECTION_ERROR';

export const SEND_AUTHENTICATION_RECEIVED_REQUEST = 'app/SendInvitePage/SEND_AUTHENTICATION_RECEIVED_REQUEST';
export const SEND_AUTHENTICATION_RECEIVED_SUCCESS = 'app/SendInvitePage/SEND_AUTHENTICATION_RECEIVED_SUCCESS';
export const SEND_AUTHENTICATION_RECEIVED_ERROR = 'app/SendInvitePage/SEND_AUTHENTICATION_RECEIVED_ERROR';

export const SEND_AUTHENTICATION_IN_REVIEW_REQUEST = 'app/SendInvitePage/SEND_AUTHENTICATION_IN_REVIEW_REQUEST';
export const SEND_AUTHENTICATION_IN_REVIEW_SUCCESS = 'app/SendInvitePage/SEND_AUTHENTICATION_IN_REVIEW_SUCCESS';
export const SEND_AUTHENTICATION_IN_REVIEW_ERROR = 'app/SendInvitePage/SEND_AUTHENTICATION_IN_REVIEW_ERROR';

export const SEND_PRODUCT_APPROVAL_REQUEST = 'app/SendInvitePage/SEND_PRODUCT_APPROVAL_REQUEST';
export const SEND_PRODUCT_APPROVAL_SUCCESS = 'app/SendInvitePage/SEND_PRODUCT_APPROVAL_SUCCESS';
export const SEND_PRODUCT_APPROVAL_ERROR = 'app/SendInvitePage/SEND_PRODUCT_APPROVAL_ERROR';

export const SEND_PRODUCT_REJECTION_REQUEST = 'app/SendInvitePage/SEND_PRODUCT_REJECTION_REQUEST';
export const SEND_PRODUCT_REJECTION_SUCCESS = 'app/SendInvitePage/SEND_PRODUCT_REJECTION_SUCCESS';
export const SEND_PRODUCT_REJECTION_ERROR = 'app/SendInvitePage/SEND_PRODUCT_REJECTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  sendAuthenticationApprovalInProgress: false,
  sendAuthenticationApprovalSuccess: false,
  sendAuthenticationApprovalError: null,
  sendAuthenticationReceivedInProgress: false,
  sendAuthenticationReceivedSuccess: false,
  sendAuthenticationReceivedError: null,
  sendAuthenticationInReviewInProgress: false,
  sendAuthenticationInReviewSuccess: false,
  sendAuthenticationInReviewError: null,
  sendAuthenticationRejectionInProgress: false,
  sendAuthenticationRejectionSuccess: false,
  sendAuthenticationRejectionError: null,
  sendProductApprovalInProgress: false,
  sendProductApprovalSuccess: false,
  sendProductApprovalError: null,
  sendProductRejectionInProgress: false,
  sendProductRejectionSuccess: false,
  sendProductRejectionError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEND_AUTHENTICATION_APPROVAL_REQUEST:
      return { ...state, sendAuthenticationApprovalInProgress: true, sendAuthenticationApprovalSuccess: false, sendAuthenticationApprovalError: null };
    case SEND_AUTHENTICATION_APPROVAL_SUCCESS:
      return { ...state, sendAuthenticationApprovalInProgress: false, sendAuthenticationApprovalSuccess: true, };
    case SEND_AUTHENTICATION_APPROVAL_ERROR:
      return { ...state, sendAuthenticationApprovalSuccess: false, sendAuthenticationApprovalInProgress: false, sendAuthenticationApprovalError: payload };

    case SEND_AUTHENTICATION_REJECTION_REQUEST:
      return { ...state, sendAuthenticationRejectionInProgress: true, sendAuthenticationRejectionSuccess: false, sendAuthenticationRejectionError: null };
    case SEND_AUTHENTICATION_REJECTION_SUCCESS:
      return { ...state, sendAuthenticationRejectionInProgress: false, sendAuthenticationRejectionSuccess: true, };
    case SEND_AUTHENTICATION_REJECTION_ERROR:
      return { ...state, sendAuthenticationRejectionSuccess: false, sendAuthenticationRejectionInProgress: false, sendAuthenticationRejectionError: payload };

    case SEND_AUTHENTICATION_RECEIVED_REQUEST:
      return { ...state, sendAuthenticationReceivedInProgress: true, sendAuthenticationReceivedSuccess: false, sendAuthenticationReceivedError: null };
    case SEND_AUTHENTICATION_RECEIVED_SUCCESS:
      return { ...state, sendAuthenticationReceivedInProgress: false, sendAuthenticationReceivedSuccess: true, };
    case SEND_AUTHENTICATION_RECEIVED_ERROR:
      return { ...state, sendAuthenticationReceivedSuccess: false, sendAuthenticationReceivedInProgress: false, sendAuthenticationReceivedError: payload };

    case SEND_AUTHENTICATION_IN_REVIEW_REQUEST:
      return { ...state, sendAuthenticationInReviewInProgress: true, sendAuthenticationInReviewSuccess: false, sendAuthenticationInReviewError: null };
    case SEND_AUTHENTICATION_IN_REVIEW_SUCCESS:
      return { ...state, sendAuthenticationInReviewInProgress: false, sendAuthenticationInReviewSuccess: true, };
    case SEND_AUTHENTICATION_IN_REVIEW_ERROR:
      return { ...state, sendAuthenticationInReviewSuccess: false, sendAuthenticationInReviewInProgress: false, sendAuthenticationInReviewError: payload };

    case SEND_PRODUCT_APPROVAL_REQUEST:
      return { ...state, sendProductApprovalInProgress: true, sendProductApprovalSuccess: false, sendProductApprovalError: null };
    case SEND_PRODUCT_APPROVAL_SUCCESS:
      return { ...state, sendProductApprovalInProgress: false, sendProductApprovalSuccess: true, };
    case SEND_PRODUCT_APPROVAL_ERROR:
      return { ...state, sendProductApprovalSuccess: false, sendProductApprovalInProgress: false, sendProductApprovalError: payload };

    case SEND_PRODUCT_REJECTION_REQUEST:
      return { ...state, sendProductRejectionInProgress: true, sendProductRejectionSuccess: false, sendProductRejectionError: null };
    case SEND_PRODUCT_REJECTION_SUCCESS:
      return { ...state, sendProductRejectionInProgress: false, sendProductRejectionSuccess: true, };
    case SEND_PRODUCT_REJECTION_ERROR:
      return { ...state, sendProductRejectionSuccess: false, sendProductRejectionInProgress: false, sendProductRejectionError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const sendAuthenticationApprovalRequest = () => ({
  type: SEND_AUTHENTICATION_APPROVAL_REQUEST,
});

export const sendAuthenticationApprovalSuccess = () => ({
  type: SEND_AUTHENTICATION_APPROVAL_SUCCESS,
});

export const sendAuthenticationApprovalError = e => ({
  type: SEND_AUTHENTICATION_APPROVAL_ERROR,
  payload: e,
});

export const sendAuthenticationRejectionRequest = () => ({
  type: SEND_AUTHENTICATION_REJECTION_REQUEST,
});

export const sendAuthenticationRejectionSuccess = () => ({
  type: SEND_AUTHENTICATION_REJECTION_SUCCESS,
});

export const sendAuthenticationRejectionError = e => ({
  type: SEND_AUTHENTICATION_REJECTION_ERROR,
  payload: e,
});

export const sendAuthenticationReceivedRequest = () => ({
  type: SEND_AUTHENTICATION_RECEIVED_REQUEST,
});

export const sendAuthenticationReceivedSuccess = () => ({
  type: SEND_AUTHENTICATION_RECEIVED_SUCCESS,
});

export const sendAuthenticationReceivedError = e => ({
  type: SEND_AUTHENTICATION_RECEIVED_ERROR,
  payload: e,
});

export const sendAuthenticationInReviewRequest = () => ({
  type: SEND_AUTHENTICATION_IN_REVIEW_REQUEST,
});

export const sendAuthenticationInReviewSuccess = () => ({
  type: SEND_AUTHENTICATION_IN_REVIEW_SUCCESS,
});

export const sendAuthenticationInReviewError = e => ({
  type: SEND_AUTHENTICATION_IN_REVIEW_ERROR,
  payload: e,
});

export const sendProductApprovalRequest = () => ({
  type: SEND_PRODUCT_APPROVAL_REQUEST,
});

export const sendProductApprovalSuccess = () => ({
  type: SEND_PRODUCT_APPROVAL_SUCCESS,
});

export const sendProductApprovalError = e => ({
  type: SEND_PRODUCT_APPROVAL_ERROR,
  payload: e,
});

export const sendProductRejectionRequest = () => ({
  type: SEND_PRODUCT_REJECTION_REQUEST,
});

export const sendProductRejectionSuccess = () => ({
  type: SEND_PRODUCT_REJECTION_SUCCESS,
});

export const sendProductRejectionError = e => ({
  type: SEND_PRODUCT_REJECTION_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const sendAuthenticationApproval =
  (authorId, authorName, listingId, listingTitle, reason, referenceNumber) =>
    (dispatch) =>
{
  dispatch(sendAuthenticationApprovalRequest());

  const data = { authorId, authorName, listingId, listingTitle, reason, referenceNumber };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-authentication-approval`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendAuthenticationApprovalSuccess());
      dispatch(showListing(listingId));
      console.log('Successfully sent authentication approval');
      return result;
    })
    .catch( error => {
      dispatch(sendAuthenticationApprovalError(error.message));
      console.error('Sending authentication approval failed with an error: ' + error.message);
      return error;
    });
};

export const sendAuthenticationRejection = (authorId, authorName, listingId, listingTitle, reason) => (dispatch) => {
  dispatch(sendAuthenticationRejectionRequest());

  const data = { authorId, authorName, listingId, listingTitle, reason };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-authentication-rejection`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendAuthenticationRejectionSuccess());
      console.log('Successfully sent authentication rejection');
      return result;
    })
    .catch( error => {
      dispatch(sendAuthenticationRejectionError(error.message));
      console.error('Sending authentication rejection failed with an error: ' + error.message);
      return error;
    });
};

export const sendAuthenticationReceived = (listingId, listingTitle, authenticationLink, referenceNumber) => (dispatch) => {
  dispatch(sendAuthenticationReceivedRequest());

  const data = { listingId, listingTitle, authenticationLink, referenceNumber };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-authentication-received`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendAuthenticationReceivedSuccess());
      dispatch(showListing(listingId));
      console.log('Successfully updated authentication status as "received"');
      return result;
    })
    .catch( error => {
      dispatch(sendAuthenticationReceivedError(error.message));
      console.error('Sending authentication status update failed with an error: ' + error.message);
      return error;
    });
};

export const sendAuthenticationInReview = (listingId, listingTitle, authenticationLink, referenceNumber) => (dispatch) => {
  dispatch(sendAuthenticationInReviewRequest());

  const data = { listingId, listingTitle, authenticationLink, referenceNumber };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-authentication-in-review`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendAuthenticationInReviewSuccess());
      dispatch(showListing(listingId));
      console.log('Successfully updated authentication status as "under_review"');
      return result;
    })
    .catch( error => {
      dispatch(sendAuthenticationInReviewError(error.message));
      console.error('Sending authentication status update failed with an error: ' + error.message);
      return error;
    });
};


export const sendProductApproval = (authorId, authorName, listingId, listingTitle) => (dispatch) => {
  dispatch(sendProductApprovalRequest());

  const data = { authorId, authorName, listingId, listingTitle };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-product-approval`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendProductApprovalSuccess());
      dispatch(showListing(listingId));
      console.log('Successfully sent product approval');
      return result;
    })
    .catch( error => {
      dispatch(sendProductApprovalError(error.message));
      console.error('Sending product approval failed with an error: ' + error.message);
      return error;
    });
};

export const sendProductRejection = (authorId, authorName, listingId, listingTitle) => (dispatch) => {
  dispatch(sendProductRejectionRequest());

  const data = { authorId, authorName, listingId, listingTitle};

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-product-rejection`,
  };

  return axios(options)
    .then( result => {
      dispatch(sendProductRejectionSuccess());
      console.log('Successfully sent product rejection');
      return result;
    })
    .catch( error => {
      dispatch(sendProductRejectionError(error.message));
      console.error('Sending product rejection failed with an error: ' + error.message);
      return error;
    });
};



export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing, couponCode }) => dispatch => {
  dispatch(fetchLineItemsRequest());

  transactionLineItems({ bookingData, listingId, isOwnListing, couponCode })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

const loadListingData = (params, listingId) => dispatch => {
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.enableAvailability) {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
    ]);
  } else {
    return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]);
  }
};

export const loadData = (params, search) => (dispatch, getState) => {
  const state = getState();
  const { currentUser } = state.user;

  const listingId = new UUID(params.id);

  dispatch(fetchMainAdminUser());

  return dispatch(loadListingData(params, listingId))
    .then(() => {
      const ref = { id: listingId, type: 'listing' };
      const listings = getMarketplaceEntities(state, [ref]);
      const listing = listings.length === 1 ? listings[0] : null;

      if(listing && listing.attributes.publicData && listing.attributes.publicData.authenticationStatus &&
         listing.attributes.publicData.authenticationStatus === 'requested' &&
         currentUser && currentUser.attributes.profile && currentUser.attributes.profile.metadata &&
         currentUser.attributes.profile.metadata.isAdmin)
      {
        const slug = createSlug(listing.attributes.title);
        const authenticationLink = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/cl/${slug}/${params.id}`;

        return dispatch(sendAuthenticationReceived(listingId, listing.attributes.title, authenticationLink));
      } else {
        return Promise.resolve(null);
      }
    });
};
