import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.077 2.123c-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.923 5.877.019 4.123.323 5.699 2.923 5.877 2.4.163 7.751.164 10.153 0 2.598-.177 2.904-1.747 2.923-5.877-.019-4.123-.323-5.699-2.923-5.877zm-7.077 8.544v-5.333l5.333 2.662-5.333 2.671z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
