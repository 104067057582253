import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        <b>Registrar</b><br/>
        Own Kicks, Inc DBA Resale Force®
      </p>

      <p>
        <b>Name of the register</b><br/>
        The Business Services Division for the State of Connecticut.
        C Corporation in Delaware, USA
      </p>

      <p>
        <b>Use of personal details (purpose of register)</b><br/>
        Personal details are collected to make communication and use of service possible.
        Details can be used for communication between service providers and users
        and also for direct communication between users. Some personal details are
        visible on the profile page of the user, but those details are voluntary (except name).
        <br/><br/>
        The handling of personal details is not outsourced, but the register
        data is stored on a server that is rented from a third party company.
      </p>

      <p>
        <b>Information content of the register</b><br/>
        The following information may be stored in the register:
        <br/>
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>Personal details: Name, email address, phone number, street address</li>
          <li style={{fontSize: '16px'}}>Account details: username, password (stored in encrypted format)</li>
          <li style={{fontSize: '16px'}}>The description text that the user may write about him/herself</li>
          <li style={{fontSize: '16px'}}>The offers and requests the user has posted to the service</li>
          <li style={{fontSize: '16px'}}>The given and received feedback and badges</li>
          <li style={{fontSize: '16px'}}>Statistical data about service usage, e.g. number times the user has logged in</li>
        </ul>
      </p>

      <p>
        <b>Regular sources of information</b><br/>
        Personal details are given by the user on registration to the service or when using it later.
      </p>

      <p>
        <b>Regular handovers of the information</b><br/>
        The information may be handed over for research purposes as described
        in the Terms of use that the user accepts before starting to use the service.
        The researchers may not publish any research results so that identifying information
        would be revealed or that any specific user could be detected.
        <br/><br/>
        Information considering users of a single Ownkicks, Inc DBA Resale Force®
        community may be handed over to the client who has founded that community
        or to the community administrators appointed by that client.
      </p>

      <p>
        <b>Transfers of the information outside the EU and the European Economic Area</b><br/>
        Information may be stored to a server that may be located inside or outside of the EU
        and the European Economic Area
      </p>

      <p>
        <b>Register protection principles</b><br/>
        The information is stored on computers. The access to the information
        is restricted with passwords and physical access to the computers
        is restricted by the server hosting company.
      </p>

      <h2>
        Cookie Policy for Ownkicks, Inc DBA Resale Force®
      </h2>

      <p>
        This is the Cookie Policy for Ownkicks, Inc DBA Resale Force®, accessible
        from https://www.resaleforce.com/
      </p>

      <p>
        <b>What Are Cookies</b><br/>
        As is common practice with almost all professional websites this site uses cookies,
        which are tiny files that are downloaded to your computer, to improve your experience.
        This page describes what information they gather, how we use it and why
        we sometimes need to store these cookies. We will also share how you
        can prevent these cookies from being stored however this may downgrade
        or 'break' certain elements of the sites functionality.
        <br/><br/>
        For more general information on cookies see the Wikipedia article on HTTP Cookies.
      </p>

      <p>
        <b>How We Use Cookies</b><br/>
        We use cookies for a variety of reasons detailed below. Unfortunately
        in most cases there are no industry standard options for disabling cookies
        without completely disabling the functionality and features they add to this site.
        It is recommended that you leave on all cookies if you are not sure whether
        you need them or not in case they are used to provide a service that you use.
      </p>

      <p>
        <b>Disabling Cookies</b><br/>
        You can prevent the setting of cookies by adjusting the settings on your browser
        (see your browser Help for how to do this). Be aware that disabling cookies
        will affect the functionality of this and many other websites that you visit.
        Disabling cookies will usually result in also disabling certain functionality
        and features of the this site. Therefore it is recommended that you do not disable cookies.
      </p>

      <p>
        <b>The Cookies We Set</b><br/>
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            Account related cookies<br/><br/>
            If you create an account with us then we will use cookies for the management
            of the signup process and general administration. These cookies
            will usually be deleted when you log out however in some cases
            they may remain afterwards to remember your site preferences when logged out.<br/><br/>
          </li>
          <li style={{fontSize: '16px'}}>
            Orders processing related cookies<br/><br/>
            This site offers e-commerce or payment facilities and some cookies
            are essential to ensure that your order is remembered between
            pages so that we can process it properly.<br/><br/>
          </li>
          <li style={{fontSize: '16px'}}>
            Site preferences cookies<br/><br/>
            In order to provide you with a great experience on this site we provide
            the functionality to set your preferences for how this site runs when
            you use it. In order to remember your preferences we need to set cookies
            so that this information can be called whenever you interact with a page
            is affected by your preferences.<br/><br/>
          </li>
        </ul>
      </p>

      <p>
        <b>Third Party Cookies</b><br/>
        In some special cases we also use cookies provided by trusted third parties.
        The following section details which third party cookies you might encounter through this site.
        <br/>
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            This site uses Google Analytics and Matomo which are one of the most widespread
            and trusted analytics solution on the web for helping us to understand
            how you use the site and ways that we can improve your experience.
            These cookies may track things such as how long you spend on the site
            and the pages that you visit so we can continue to produce engaging content.<br/><br/>
            For more information on Google Analytics and Matomo cookies, see
            the official Google Analytics and Matomo page.<br/><br/>
          </li>
          <li style={{fontSize: '16px'}}>
            As we sell products it's important for us to understand statistics
            about how many of the visitors to our site actually make a purchase
            and as such this is the kind of data that these cookies will track.
            This is important to you as it means that we can accurately make
            business predictions that allow us to monitor our advertising and
            product costs to ensure the best possible price.<br/><br/>
          </li>
          <li style={{fontSize: '16px'}}>
            The Google AdSense service we use to serve advertising uses a DoubleClick
            cookie to serve more relevant ads across the web and limit
            the number of times that a given ad is shown to you.<br/><br/>
            For more information on Google AdSense see the official
            Google AdSense privacy FAQ.<br/><br/>
          </li>
          <li style={{fontSize: '16px'}}>
            We also use social media buttons and/or plugins on this site that
            allow you to connect with your social network in various ways.
            For these to work the following social media sites including;
            Facebook, Instagram and Twitter, will set cookies through our site
            which may be used to enhance your profile on their site or contribute
            to the data they hold for various purposes outlined in their
            respective privacy policies.<br/><br/>
          </li>
        </ul>
      </p>

      <p>
        <b>More Information</b><br/>
        Hopefully that has clarified things for you and as was previously mentioned
        if there is something that you aren't sure whether you need or not it's
        usually safer to leave cookies enabled in case it does interact with one
        of the features you use on our site. This Cookies Policy was created with the help of the
        &nbsp;
        <ExternalLink
          key="policyGeneratorLink"
          href={'https://www.cookiepolicygenerator.com/'}
          title={'Cookies Policy Template Generator'}
        >
          Cookies Policy Template Generator
        </ExternalLink>
        &nbsp;
        and the
        &nbsp;
        <ExternalLink
          key="termsAndConditionsTemplateLink"
          href={'https://www.termsandcondiitionssample.com/'}
          title={'Terms and Conditions Template'}
        >
          Terms and Conditions Template
        </ExternalLink>
        &nbsp;
        .<br/><br/>
        However if you are still looking for more information then you can
        contact us through one of our preferred contact methods:

        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            EMail :&nbsp;
            <ExternalLink
              key="emailLink"
              href={'mailto:hello@resaleforce.com'}
              title={'EMail'}
            >
              hello@resaleforce.com
            </ExternalLink>
          </li>
          <li style={{fontSize: '16px'}}>
            Telephone :&nbsp;
            <ExternalLink
              key="phoneLink"
              href={'tel:203-941-0470'}
              title={'Phone'}
            >
              203-941-0470
            </ExternalLink>
            &nbsp;(Text or Call)
          </li>
        </ul>
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
