import React from 'react';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { NamedLink, InlineTextButton } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.css';

export const ActionBarMaybe = props => {
  const {
    isOwnListing,
    isAdmin,
    listing,
    editParams,
    handleApprove,
    handleReject,
    sendAuthenticationApprovalInProgress,
    sendAuthenticationApprovalSuccess,
    sendAuthenticationApprovalError,
    sendAuthenticationRejectionInProgress,
    sendAuthenticationRejectionSuccess,
    sendAuthenticationRejectionError,
    sendAuthenticationInReviewInProgress,
    sendAuthenticationInReviewSuccess,
    sendAuthenticationInReviewError,
    sendProductApprovalInProgress,
    sendProductApprovalSuccess,
    sendProductApprovalError,
    sendProductRejectionInProgress,
    sendProductRejectionSuccess,
    sendProductRejectionError,
  } = props;
  const state = listing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const publicData = listing.attributes.publicData || {};
  const isPendingAdminApproval = publicData.pendingAdminApproval;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isSaleListing = publicData.listingType && publicData.listingType === 'sale';

  const isInProgress =
    sendAuthenticationApprovalInProgress || sendAuthenticationRejectionInProgress ||
    sendProductApprovalInProgress || sendProductRejectionInProgress;
  const isError = !!(
    sendAuthenticationApprovalError || sendAuthenticationRejectionError ||
    sendProductApprovalError || sendProductRejectionError
  );
  const isSuccess =
    sendAuthenticationApprovalSuccess || sendProductApprovalSuccess ||
    sendAuthenticationRejectionSuccess || sendProductRejectionSuccess;

  const successfulApproval =
    sendAuthenticationApprovalSuccess || sendProductApprovalSuccess;
  const successfulRejection =
    sendAuthenticationRejectionSuccess || sendProductRejectionSuccess;

  const successfulApprovalMessage = successfulApproval ? (
    <p className={css.approvalSuccessText}>
      The listing is successfully approved.
    </p>
  ) : null;

  const successfulRejectionMessage = successfulRejection ? (
    <p className={css.approvalSuccessText}>
      The listing is successfully rejected. You might want to either close or delete it via Flex Admin Console.
    </p>
  ) : null;

  const inProgressMessage = isInProgress ? (
    <p className={css.approvalInProgressText}>
      In progress...
    </p>
  ) : null;

  const errorMessage = isError ? (
    <p className={css.approvalFailureText}>
      {sendAuthenticationApprovalError || sendAuthenticationRejectionError ||
      sendProductApprovalError || sendProductRejectionError}
    </p>
  ) : null;

  if(isAdmin && isPendingAdminApproval && !isClosed) {
    const pendingApprovalText = (!isInProgress && !isError && !isSuccess) ? (
      <p className={css.pendingApprovalByAdminText}>
        <FormattedMessage id='ListingPage.pendingApprovalByAdmin'/>
      </p>
    ) : null;

    return (
      <div className={css.actionBar}>
        {pendingApprovalText}
        {inProgressMessage}
        {successfulApprovalMessage}
        {successfulRejectionMessage}
        {errorMessage}
        <div>
          <InlineTextButton className={css.approveListingLink} onClick={handleApprove}>
            { listing.attributes.publicData.authenticationStatus === 'under_review' ? (
                <FormattedMessage id="ListingPage.approveListing"/>
              ) : (
                <FormattedMessage id="ListingPage.underReviewListing"/>
              )
            }
          </InlineTextButton>
          <InlineTextButton className={css.rejectListingLink} onClick={handleReject}>
            <FormattedMessage id="ListingPage.rejectListing"/>
          </InlineTextButton>
        </div>
      </div>
    );
  } else if(isAdmin && !isClosed){
    return (
      <div className={css.actionBar}>
        {inProgressMessage}
        {successfulApprovalMessage}
        {successfulRejectionMessage}
        {errorMessage}
      </div>
    )
  } else if (isOwnListing) {
    let ownListingTextTranslationId = 'ListingPage.ownListing';

    if (isPendingApproval) {
      ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    } else if (isClosed) {
      ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    } else if (isDraft) {
      ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const message = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';
    const editListingPageName = isSaleListing ? 'EditListingPage' : 'EditCatalogListingPage';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    return (
      <div className={css.actionBar}>
        <p className={ownListingTextClasses}>
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        <NamedLink className={css.editListingLink} name={editListingPageName} params={editParams}>
          <EditIcon className={css.editIcon} />
          <FormattedMessage id={message} />
        </NamedLink>
      </div>
    );
  } else if (isClosed) {
    return (
      <div className={css.actionBar}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }

  return null;
};

ActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
