import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  fetchUsers, openKeyPair, createKeyPair, deleteKeyPair
} from './AdminManageUsersPage.duck';

import css from './AdminManageUsersPage.css';

const API_KEY_LENGTH = 32;
const API_SECRET_LENGTH = 40;

const API_KEY_STATE_ABSENT = 'api-key-state-absent';
const API_KEY_STATE_PRESENT = 'api-key-state-present';
const API_KEY_STATE_OPEN = 'api-key-state-open';

const convertToTableData = users => {
  if( Array.isArray( users )) {
    return users.map( user => {
      return {
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        createdAt: ( new Date( user.createdAt )).toLocaleDateString("en-US"),
        apiKey: user.apiKey,
        apiSecret: user.apiSecret,
        apiKeyControl:
          typeof user.apiKey === 'string'
            ? user.apiKey.length === API_KEY_LENGTH
              ? API_KEY_STATE_OPEN
              : API_KEY_STATE_PRESENT
            : API_KEY_STATE_ABSENT,
        banned: user.banned ? 'Yes' : 'No',
        deleted: user.deleted ? 'Yes' : 'No',
      };
    })
  }

  return [];
};

export class AdminManageUsersPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      pagination,
      fetchUsersInProgress,
      fetchUsersError,
      users,
      keyPairUserId,
      openKeyPairInProgress,
      openKeyPairError,
      createKeyPairInProgress,
      createKeyPairError,
      deleteKeyPairInProgress,
      deleteKeyPairError,
      scrollingDisabled,
      intl,
      onFetchUsers,
      onOpenKeyPair,
      onCreateKeyPair,
      onDeleteKeyPair,
    } = this.props;

    const isMainAdmin = !!( currentUser?.attributes?.profile?.metadata?.isAdmin &&
      currentUser?.id.uuid === process.env.REACT_APP_MAIN_ADMIN_ID );

    if( !isMainAdmin ){
      return <NamedRedirect name="LandingPage" />;
    }

    const columns = [
      {
        name: <div style={{ fontSize: '16px'}}>Id</div>,
        selector: 'id',
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Full Name</div>,
        selector: 'fullName',
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>EMail</div>,
        selector: 'email',
        center: true,
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Created At</div>,
        selector: 'createdAt',
        sortable: true,
        style: { fontSize: '16px' },
        maxWidth: '120px',
      },
      {
        name: <div style={{ fontSize: '16px'}}>API Key</div>,
        selector: 'apiKey',
        sortable: false,
        style: { fontSize: '16px' },
        minWidth: '350px',
      },
      {
        name: <div style={{ fontSize: '16px'}}>API Secret</div>,
        selector: 'apiSecret',
        sortable: false,
        style: { fontSize: '16px' },
        minWidth: '420px',
      },
      {
        name: '',
        selector: 'controlApiKeyPair',
        sortable: false,
        maxWidth: '180px',
        cell: row => {
          switch (row.apiKeyControl) {
            case API_KEY_STATE_ABSENT:
              return (
                <div className={css.editCell} onClick={() => onCreateKeyPair( row.id )} >
                  Create API Key Pair
                </div>
              );
            case API_KEY_STATE_PRESENT:
              return (
                <div className={css.editCell} onClick={() => onOpenKeyPair( row.id )} >
                  Open API Key Pair
                </div>
              );
            case API_KEY_STATE_OPEN:
              return (
                <div className={css.editCell} onClick={() => onDeleteKeyPair( row.id )} >
                  Delete API Key Pair
                </div>
              );
          }
        },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Banned</div>,
        selector: 'banned',
        center: true,
        sortable: false,
        style: { fontSize: '16px'},
        maxWidth: '60px',
      },
      {
        name: <div style={{ fontSize: '16px'}}>Deleted</div>,
        selector: 'deleted',
        center: true,
        sortable: false,
        style: { fontSize: '16px'},
        maxWidth: '60px',
      },
    ];

    const data = Array.isArray( users ) ? convertToTableData( users ) : [];
    const title = intl.formatMessage({ id: 'AdminManageUsersPage.title' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="AdminManageUsersPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.couponPanel}>
              <div className={css.dataTableWrapper}>
                <DataTable
                  style={{ fontSize: '16px!important'}}
                  keyField={'code'}
                  defaultSortField={'code'}
                  striped={true}
                  highlightOnHover={true}
                  columns={columns}
                  data={data}
                  pagination
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AdminManageUsersPageComponent.defaultProps = {
  updateProfileError: null,
};

const { bool, func } = PropTypes;

AdminManageUsersPageComponent.propTypes = {
  onUpdateProfile: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    pagination,
    fetchUsersInProgress,
    fetchUsersError,
    users,
    keyPairUserId,
    openKeyPairInProgress,
    openKeyPairError,
    createKeyPairInProgress,
    createKeyPairError,
    deleteKeyPairInProgress,
    deleteKeyPairError,
  } = state.AdminManageUsersPage;

  return {
    currentUser,
    pagination,
    fetchUsersInProgress,
    fetchUsersError,
    users,
    keyPairUserId,
    openKeyPairInProgress,
    openKeyPairError,
    createKeyPairInProgress,
    createKeyPairError,
    deleteKeyPairInProgress,
    deleteKeyPairError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchUsers: page => dispatch( fetchUsers( page )),
  onOpenKeyPair: userId => dispatch( openKeyPair( userId )),
  onCreateKeyPair: userId => dispatch( createKeyPair( userId )),
  onDeleteKeyPair: userId => dispatch( deleteKeyPair( userId )),
});

const AdminManageUsersPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AdminManageUsersPageComponent);

AdminManageUsersPage.loadData = () => {
  return fetchUsers( 1 );
};

export default AdminManageUsersPage;
