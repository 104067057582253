exports.filtersConfig = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'owner_type',
    label: 'Owner Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_ownerType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'individual', label: 'Individual' },
        { key: 'business', label: 'Business' },
      ],
    },
  },
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_brand'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'adidas', label: 'Adidas' },
        { key: 'air_jordan', label: 'Air Jordan' },
        { key: 'burberry', label: 'Burberry' },
        { key: 'chanel', label: 'Chanel' },
        { key: 'clarks', label: 'Clarks' },
        { key: 'coach', label: 'Coach' },
        { key: 'converse', label: 'Converse' },
        { key: 'colombia', label: 'Colombia' },
        { key: 'forever', label: 'Forever' },
        { key: 'gucci', label: 'Gucci' },
        { key: 'l_l_bean', label: 'L.L. Bean' },
        { key: 'lucky_brand', label: 'Lucky Brand' },
        { key: 'new_balance', label: 'New Balance' },
        { key: 'nike', label: 'Nike' },
        { key: 'off_white', label: 'Off-White' },
        { key: 'puma', label: 'Puma' },
        { key: 'reebok', label: 'Reebok' },
        { key: 'skechers', label: 'Skechers' },
        { key: 'steve_madden', label: 'Steve Madden' },
        { key: 'timberland', label: 'Timberland' },
        { key: 'ugg', label: 'UGG' },
        { key: 'versce', label: 'Versace' },
        { key: 'yeezy', label: 'Yeezy' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'condition',
    label: 'Item Condition',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_condition'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'used', label: 'Used' },
        { key: 'new', label: 'New' },
      ],
    },
  },
  {
    id: 'shoe_size',
    label: 'Shoe Size',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_shoe_size'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'm4_w55', label: 'M 4 / W 5.5' },
        { key: 'm45_w6', label: 'M 4.5 / W 6' },
        { key: 'm5_w65', label: 'M 5 / W 6.5' },
        { key: 'm55_w7', label: 'M 5.5 / W 7' },
        { key: 'm6_w75', label: 'M 6 / W 7.5' },
        { key: 'm65_w8', label: 'M 6.5 / W 8' },
        { key: 'm7_w85', label: 'M 7 / W 8.5' },
        { key: 'm75_w9', label: 'M 7.5 / W 9' },
        { key: 'm8_w95', label: 'M 8 / W.9.5' },
        { key: 'm85_w10', label: 'M 8.5 / W 10' },
        { key: 'm9_w105', label: 'M 9 / W 10.5' },
        { key: 'm95_w11', label: 'M 9.5 / W 11' },
        { key: 'm10_w115', label: 'M 10 / W 11.5' },
        { key: 'm105_w12', label: 'M 10.5 / W 12' },
        { key: 'm11_w125', label: 'M 11 / W 12.5' },
        { key: 'm115_w13', label: 'M 11.5 / W 13' },
        { key: 'm12_w135', label: 'M 12 / W 13.5' },
        { key: 'm125_w14', label: 'M 12.5 / W 14' },
        { key: 'm13_w145', label: 'M 13 / W 14.5' },
        { key: 'm135_w15', label: 'M 13.5 / W 15' },
        { key: 'm14_w155', label: 'M 14 / W 15.5' },
        { key: 'm15_w165', label: 'M 15 / W 16.5' },
        { key: 'm155_w17', label: 'M 15.5 / W 17' },
        { key: 'm16_w175', label: 'M 16 / W 17.5' },
        { key: 'm165_w18', label: 'M 16.5 / W 18' },
        { key: 'm17_w185', label: 'M 17 / W 18.5' },
        { key: 'm175_w19', label: 'M 17.5 / W 19' },
        { key: 'm18_195', label: 'M 18 / W 19.5' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'delivery_methods',
    label: 'Delivery Methods',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_delivery_methods'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'shipping',
          label: 'Shipping',
        },
        {
          key: 'pickup',
          label: 'Pickup',
        },
      ],
    },
  },
];
