import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import SearchForm from './SearchForm';

import css from './SectionSearch.css';

const SectionSearch = props => {
  const {
    rootClassName,
    className,
    onSearchSubmit,
    currentUser,
    initialSearchFormValues,
    authenticationListing,
    startReferenceNumber,
    searchInProgress,
    searchComplete,
    searchError,
    onStatusChangeSubscribe,
    onStatusChangeUnsubscribe,
    subscribeInProgress,
    subscribeError,
    unsubscribeInProgress,
    unsubscribeError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const referenceNumber = authenticationListing ?
    authenticationListing.attributes.publicData.referenceNumber : null;
  const authenticationStatus = authenticationListing ?
    authenticationListing.attributes.publicData.authenticationStatus : null;
  const approvalRejectionReason = ['approved', 'rejected'].includes(authenticationStatus) ?
    authenticationListing.attributes.publicData.approvalRejectionReason : null;
  const statusUpdatedDate = authenticationListing ?
    new Date(authenticationListing.attributes.publicData.statusUpdatedAt) : null;

  let authenticationStatusName = null;
  let certificateLink = null;
  switch(authenticationStatus){
    case 'requested':
      authenticationStatusName = 'Authentication request is sent';
      break;
    case 'received':
      authenticationStatusName = 'Authentication request was received and opened by admin';
      break;
    case 'under_review':
      authenticationStatusName = 'Admin has marked the authentication request as being under review';
      break;
    case 'approved':
      authenticationStatusName = 'Admin has approved the authentication request';
      certificateLink = (
        <a href={`/certificates/certificate-${authenticationListing.id.uuid}.pdf`} target={'_blank'}>
          Download Certificate of Authenticity
        </a>
      );

      break;
    case 'rejected':
      authenticationStatusName = 'Admin has rejected the authentication request';
      break;
  }

  const subscriberIds =
    authenticationListing && Array.isArray(authenticationListing.attributes.publicData.subscribers) ?
      authenticationListing.attributes.publicData.subscribers : [];

  const isSubscribed = authenticationListing && authenticationStatus !== 'approved' &&
    subscriberIds.includes( currentUser.id.uuid );

  const handleSubscribe = () => {
    const newSubscriberIds = [...subscriberIds];

    newSubscriberIds.push(currentUser.id.uuid);
    onStatusChangeSubscribe(authenticationListing.id.uuid, newSubscriberIds);
  };

  const handleUnsubscribe = () => {
    const newSubscriberIds = subscriberIds.filter(
      subscriberId => subscriberId !== currentUser.id.uuid
    );

    onStatusChangeUnsubscribe(authenticationListing.id.uuid, newSubscriberIds);
  };

  const errorMessageSubscribe = subscribeError ? (
    <p className={css.error}>
      <FormattedMessage id="SectionSearch.subscriptionFailed" />
    </p>
  ) : null;

  const errorMessageUnsubscribe = unsubscribeError ? (
    <p className={css.error}>
      <FormattedMessage id="SectionSearch.unsubscribingFailed" />
    </p>
  ) : null;

  const authenticationData = authenticationListing ? (
    <div className={css.authenticationData}>
      <div className={css.authenticationSectionTitle}>
        <FormattedMessage id="SectionSearch.authenticationSectionTitle" />
      </div>
      <div className={css.infoLine}>
        <b><FormattedMessage id="SectionSearch.referenceNumberLabel" /></b>
        {referenceNumber}
      </div>
      <div className={css.infoLine}>
        <b><FormattedMessage id="SectionSearch.authenticationStatusLabel" /></b>
        {authenticationStatusName}
      </div>
      { approvalRejectionReason ? (
          <div className={css.infoLine}>
            <b><FormattedMessage id="SectionSearch.approvalRejectionReasonLabel" /></b>
            {approvalRejectionReason}
          </div>
        ) : null
      }
      { statusUpdatedDate ? (
          <div className={css.infoLine}>
            <b><FormattedMessage id="SectionSearch.statusUpdatedAtLabel" /></b>
            {statusUpdatedDate.toDateString()}
          </div>
        ) : null
      }
      {certificateLink}
      { authenticationStatus !== 'approved' ? (
          isSubscribed ? (
            <PrimaryButton
              className={css.subscribeButton}
              type="button"
              onClick={handleUnsubscribe}
              inProgress={unsubscribeInProgress}
              disabled={unsubscribeInProgress}
              ready={false}
            >
              <FormattedMessage id={'SectionSearch.unsubscribeButton'} />
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className={css.subscribeButton}
              type="button"
              onClick={handleSubscribe}
              inProgress={subscribeInProgress}
              disabled={subscribeInProgress}
              ready={false}
            >
              <FormattedMessage id={'SectionSearch.subscribeButton'} />
            </PrimaryButton>
          )
        ) : null
      }
      <div className={css.disclaimerText}>
        <FormattedMessage id={'SectionSearch.disclaimerText'} />
      </div>
    </div>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionSearch.searchLabel" />
      </div>

      <div className={css.steps}>
        <SearchForm
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={onSearchSubmit}
          initialValues={{ referenceNumber: startReferenceNumber, ...initialSearchFormValues }}
          authenticationListing={authenticationListing}
          searchInProgress={searchInProgress}
          searchComplete={searchComplete}
          searchError={searchError}
        />
      </div>

      {authenticationData}
      {errorMessageSubscribe}
      {errorMessageUnsubscribe}
    </div>
  );
};

SectionSearch.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSearch.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSearch;
