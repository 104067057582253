import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, SecondaryButton } from '../../components';

import css from './EditCouponForm.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

const MAX_CODE_LENGTH = 30;
const MAX_TITLE_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 120;

class EditCouponFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { overallFormError: null };
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            isExistingCode,
            coupons,
            updateInProgress,
            updateProfileError,
            handleSubmit,
            intl,
            history,
            invalid,
            pristine,
            rootClassName,
            values,
          } = fieldRenderProps;

          // Coupon code
          const couponCodeLabel = intl.formatMessage({
            id: 'EditCouponForm.couponCodeLabel',
          });
          const couponCodePlaceholder = intl.formatMessage({
            id: 'EditCouponForm.couponCodePlaceholder',
          });
          const couponCodeRequiredMessage = intl.formatMessage({
            id: 'EditCouponForm.couponCodeRequired',
          });
          const couponCodeRequired = validators.required(couponCodeRequiredMessage);
          const couponCodeMaxLengthMessage = intl.formatMessage(
            { id: 'EditCouponForm.couponCodeMaximumLength' },
            {
              maximumLength: MAX_CODE_LENGTH,
            }
          );
          const couponCodeMaxLength =
            validators.maxLength(couponCodeMaxLengthMessage, MAX_CODE_LENGTH);

          // Coupon title
          const couponNameLabel = intl.formatMessage({
            id: 'EditCouponForm.couponNameLabel',
          });
          const couponNamePlaceholder = intl.formatMessage({
            id: 'EditCouponForm.couponNamePlaceholder',
          });
          const couponNameRequiredMessage = intl.formatMessage({
            id: 'EditCouponForm.couponNameRequired',
          });
          const couponNameRequired = validators.required(couponNameRequiredMessage);
          const couponNameMaxLengthMessage = intl.formatMessage(
            { id: 'EditCouponForm.couponNameMaximumLength' },
            {
              maximumLength: MAX_TITLE_LENGTH,
            }
          );
          const couponNameMaxLength =
            validators.maxLength(couponNameMaxLengthMessage, MAX_TITLE_LENGTH);

          // Coupon description
          const descriptionLabel = intl.formatMessage({
            id: 'EditCouponForm.descriptionLabel',
          });
          const descriptionPlaceholder = intl.formatMessage({
            id: 'EditCouponForm.descriptionPlaceholder',
          });
          const descriptionRequiredMessage = intl.formatMessage({
            id: 'EditCouponForm.descriptionRequired',
          });
          const descriptionRequired = validators.required(descriptionRequiredMessage);
          const descriptionMaxLengthMessage = intl.formatMessage(
            { id: 'EditCouponForm.descriptionMaximumLength' },
            {
              maximumLength: MAX_DESCRIPTION_LENGTH,
            }
          );
          const descriptionMaxLength =
            validators.maxLength(descriptionMaxLengthMessage, MAX_DESCRIPTION_LENGTH);

          // Coupon percentage discount
          const percentageLabel = intl.formatMessage({
            id: 'EditCouponForm.percentageLabel',
          });
          const percentagePlaceholder = intl.formatMessage({
            id: 'EditCouponForm.percentagePlaceholder',
          });
          const percentageMinValueMessage = intl.formatMessage({
              id: 'EditCouponForm.percentageMinValue',
            },
            {
              minPercentage: 0,
            }
          );
          const percentageMinValue = validators.minAmount( percentageMinValueMessage, 0 );
          const percentageMaxValueMessage = intl.formatMessage(
            { id: 'EditCouponForm.percentageMaxValue'},
            {
              maxPercentage: 100,
            }
          );
          const percentageMaxValue = validators.maxAmount( percentageMaxValueMessage, 100 );

          // Coupon fixed amount discount
          const fixedAmountLabel = intl.formatMessage({
            id: 'EditCouponForm.fixedAmountLabel',
          });
          const fixedAmountPlaceholder = intl.formatMessage({
            id: 'EditCouponForm.fixedAmountPlaceholder',
          });
          const fixedAmountMinValueMessage = intl.formatMessage({
              id: 'EditCouponForm.fixedAmountMinValue',
            },
            {
              minFixedAmount: 0,
            }
          );
          const fixedAmountMinValue = validators.minAmount( fixedAmountMinValueMessage, 0 );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || pristine || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                if( !values.percentage && ! values.fixed_amount ) {
                  this.setState({
                    overallFormError:
                      intl.formatMessage({ id: 'EditCouponForm.percentageOrFixedAmountError' })
                  });
                  e.preventDefault();
                } else if( !isExistingCode && typeof coupons[ values.coupon_code ] === 'object') {
                  this.setState({
                    overallFormError:
                      intl.formatMessage({ id: 'EditCouponForm.couponWithCodeAlreadyExists' })
                  });
                  e.preventDefault();
                } else {
                  handleSubmit(e);
                }
              }}
            >
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <FieldTextInput
                  type="text"
                  id="coupon_code"
                  name="coupon_code"
                  readOnly={isExistingCode}
                  label={couponCodeLabel}
                  placeholder={couponCodePlaceholder}
                  validate={validators.composeValidators( couponCodeRequired, couponCodeMaxLength )}
                  maxLength={MAX_CODE_LENGTH}
                />
                <FieldTextInput
                  type="text"
                  id="title"
                  name="title"
                  label={couponNameLabel}
                  placeholder={couponNamePlaceholder}
                  validate={validators.composeValidators( couponNameRequired, couponNameMaxLength )}
                  maxLength={MAX_TITLE_LENGTH}
                />
                <FieldTextInput
                  type="text"
                  id="description"
                  name="description"
                  label={descriptionLabel}
                  placeholder={descriptionPlaceholder}
                  validate={validators.composeValidators( descriptionRequired, descriptionMaxLength )}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                />
                <FieldTextInput
                  type="number"
                  id="percentage"
                  name="percentage"
                  label={percentageLabel}
                  placeholder={percentagePlaceholder}
                  validate={validators.composeValidators( percentageMinValue, percentageMaxValue )}
                  min={0}
                  max={100}
                  step={1}
                />
                <FieldTextInput
                  type="number"
                  id="fixed_amount"
                  name="fixed_amount"
                  label={fixedAmountLabel}
                  placeholder={fixedAmountPlaceholder}
                  validate={fixedAmountMinValue}
                  min={0}
                  step={1}
                />
              </div>
              { this.state.overallFormError ? (
                <div className={css.error}>
                  {this.state.overallFormError}
                </div>
              ) : null }
              {submitError}
              <div className={css.buttons}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={false}
                >
                  <FormattedMessage id="EditCouponPage.saveCoupon" />
                </Button>
                <SecondaryButton
                  className={css.cancelButton}
                  onClick={() => {
                    const routes = routeConfiguration();

                    // Redirect to ManageCouponsPage
                    history.push( createResourceLocatorString('ManageCouponsPage', routes, {}, {}));
                  }}
                >
                  <FormattedMessage id="EditCouponPage.cancel" />
                </SecondaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditCouponFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  updateProfileError: null,
  updateProfileReady: false,
};

EditCouponFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditCouponForm = compose(injectIntl)(EditCouponFormComponent);

EditCouponForm.displayName = 'EditCouponForm';

export default EditCouponForm;
