import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { requireAuthenticationDemo } from '../../ducks/Authentication.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { RequestDemoForm } from '../../forms';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.css';

export const LandingPageComponent = props => {
  const [ isDemoRequestModalOpen, setDemoRequestModalOpen ] = useState( false );
  const {
    history, intl, location, scrollingDisabled, onManageDisableScrolling,
    isDemoRequestSent, demoRequestError, demoRequestInProgress,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const onRequestDemo = () => {
    setDemoRequestModalOpen( true );
  };

  const onSubmitDemoRequest = values => {
    const { onRequireAuthenticationDemo } = props;

    onRequireAuthenticationDemo( values )
      .then(() => {
        setDemoRequestModalOpen( false );
      })
      .catch( e => {
        console.error( e );
      })
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              onAuthenticate={onRequestDemo}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations onAuthenticate={onRequestDemo} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  onAuthenticate={() => setDemoRequestModalOpen( true )}
                />
              </div>
            </li>
          </ul>
          <Modal
            id="LandingPage.requestDemo"
            contentClassName={css.requestDemoModalContent}
            isOpen={isDemoRequestModalOpen}
            onClose={() => setDemoRequestModalOpen( false )}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <RequestDemoForm
              className={css.requestDemoForm}
              submitButtonWrapperClassName={css.requestDemoSubmitButtonWrapper}
              isDemoRequestSent={isDemoRequestSent}
              demoRequestError={demoRequestError}
              demoRequestInProgress={demoRequestInProgress}
              onSubmit={onSubmitDemoRequest}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    isDemoRequestSent,
    demoRequestError,
    demoRequestInProgress,
  } = state.Authentication;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    isDemoRequestSent,
    demoRequestError,
    demoRequestInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onRequireAuthenticationDemo: data => dispatch(requireAuthenticationDemo( data )),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
