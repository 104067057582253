import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, FieldTextInput, Button } from '../../components';

import css from './SearchForm.css';
import { composeValidators, required, minLength } from '../../util/validators';

const REF_NUMBER_MIN_LENGTH = 10;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            pristine,
            ready,
            updated,
            invalid,
            disabled,
            authenticationListing,
            searchInProgress,
            searchComplete,
            searchError,
            handleSubmit,
          } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          const submitReady = (updated && pristine) || ready;
          const submitInProgress = searchInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const refNumberRequiredMessage = intl.formatMessage({
            id: 'SearchForm.refNumberRequired',
          });
          const minRefNumberLengthMessage = intl.formatMessage(
            { id: 'SearchForm.minLength' },
            {
              maxLength: REF_NUMBER_MIN_LENGTH,
            }
          );

          const errorMessageSearchAuthentication = searchError ? (
            <p className={css.error}>
              <FormattedMessage id="SearchForm.searchAuthenticationFailed" />
            </p>
          ) : null;

          const noresultsMessageSearchAuthentication = searchComplete && !searchError && !authenticationListing ? (
            <p className={css.success}>
              <FormattedMessage id="SearchForm.searchAuthenticationNoResults" />
            </p>
          ) : null;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <FieldTextInput
                className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                id='referenceNumber'
                name='referenceNumber'
                placeholder={intl.formatMessage({ id: 'SearchForm.placeholder' })}
                validate={
                  composeValidators(
                    required(refNumberRequiredMessage),
                    minLength(minRefNumberLengthMessage, REF_NUMBER_MIN_LENGTH)
                  )
                }
              />

              {errorMessageSearchAuthentication}
              {noresultsMessageSearchAuthentication}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id={'SearchForm.searchButton'} />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
