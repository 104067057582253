import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        We’re building a new-generation and transparent solution for the secondhand
        market. Resale Force by Ownkicks, Inc is not endorsed by the brands of
        the products we authenticate.
      </p>

      <p>Rights of Content</p>

      <p>
        The users themselves retain the right to all text, pictures and other content
        that they create in the service. The users allow others to utilize the content
        in accordance with the nature of the service and furthermore allow
        the service provider to file information and data and make changes that are
        necessary for the service or the study, however other rights are not transferred
        from the users, unless specifically otherwise agreed. The responsibility
        of the content lies with the user, who has produced it to the service.
        We "Own Kicks" have the right to remove any material/user when it deems it necessary.
      </p>

      <p>
        <b>Disclaimer</b><br/>
        No guarantees of the functioning of the service are given. The users are
        themselves responsible for their actions in the service and they should
        estimate the reliability of other users before dealing with them. Authentication
        results are based on the information submitted and at the time of review.
        It is your full responsibility to verify the certificate of authenticity
        issued by emailing hello@resaleforce.com. We will not be held liable for
        any transactions outside of our platform.
      </p>
      <p>
        <b>The Removal of a User</b><br/>
        Resaleforce has the right to remove any users from it platform and terminate
        their right of use of the service without any specific reason and without
        being liable for compensation.
      </p>

      <h2>Payment:</h2>
      <p>
        Payment processed by Stripe. We accept Visa, Master Card, American Express, Discover.
      </p>

      <p>
        <h3>AUTHENTICATION:</h3>
        Own Kicks authenticate solely on the photos uploaded and links shared by
        users. By using our service/product you agree and understand:
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            It is your full responsibility to buy from trusted sellers and
            to protect your purchase.
          </li>
          <li style={{fontSize: '16px'}}>
            You are fully responsible for any risks.
          </li>
          <li style={{fontSize: '16px'}}>
            We will not be held liable for any compensation, missing item, damage
            to the item or product.
          </li>
          <li style={{fontSize: '16px'}}>
            We do not control transactions outside Own Kicks.
          </li>
        </ul>
      </p>

      <p>
        <b>Refund/Cancellation:</b><br/>
        No refund is provided for subscription, one-time sale, and authentication
        requests. However, you may be credited with in-store credits.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
