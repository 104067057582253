import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, onAuthenticate } = props;

  const classes = classNames(rootClassName || css.root, className); // css[`root${heroSliderIndex}`], className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div
          className={css.heroButton}
          onClick={onAuthenticate}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </div>
        <ExternalLink
          className={css.authenticateLink}
          href='https://onelink.to/wcjh9b'
        >
          <div
            className={css.authenticateButton}
          >
            <FormattedMessage id="SectionHero.authenticationButton" />
          </div>
        </ExternalLink>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
