import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingDeliveryForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingDeliveryPanel.css';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

const DELIVERY_NAME = 'delivery';

const EditListingDeliveryPanelComponent = props => {
  const {
    rootClassName,
    className,
    listing,
    currentUser,
    history,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDeliveryPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDeliveryPanel.createListingTitle" />
  );

  const deliveryMethods = publicData && publicData.delivery;
  const initialValues = { delivery: deliveryMethods };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDeliveryForm
        className={css.form}
        name={DELIVERY_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const { delivery = [] } = values;
          const userShippoAccessToken =
            currentUser.attributes.profile.privateData.shippoAccessToken;

          const updatedValues = {
            publicData: { delivery },
          };

          if(values.delivery.includes('shipping') && !userShippoAccessToken){
            const shippingSwal = withReactContent(Swal);

            shippingSwal.fire({
              icon: 'warning',
              title: <p>Shipping integration action is required</p>,
              text: 'In order to select "Shipping" delivery option you need to have active Shippo account. Would you like to integrate your Shippo account now?',
              footer: 'Copyright Ownkicks, Inc DBA Resale Force',
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              showCancelButton: true,
              showCloseButton: true
            }).then(result => {
              if(result.isConfirmed) {
                history.push(createResourceLocatorString('ShippingDetailsPage', routeConfiguration()));
              }
            })
          } else {
            onSubmit(updatedValues);
          }
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingDeliveryPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingDeliveryPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const EditListingDeliveryPanel = compose(
  withRouter,
)(EditListingDeliveryPanelComponent);

export default EditListingDeliveryPanel;
