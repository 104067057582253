import { storableError } from '../../util/errors';
import {
  adminFetchBusinessUsers, adminCreateApiKeyPair, adminDeleteApiKeyPair, adminOpenApiKeyPair
} from '../../util/api';

// ================ Action types ================ //

export const FETCH_USERS_REQUEST = 'app/AdminManageUsersPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/AdminManageUsersPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/AdminManageUsersPage/FETCH_USERS_ERROR';

export const OPEN_KEY_PAIR_REQUEST = 'app/AdminManageUsersPage/OPEN_KEY_PAIR_REQUEST';
export const OPEN_KEY_PAIR_SUCCESS = 'app/AdminManageUsersPage/OPEN_KEY_PAIR_SUCCESS';
export const OPEN_KEY_PAIR_ERROR = 'app/AdminManageUsersPage/OPEN_KEY_PAIR_ERROR';

export const CREATE_KEY_PAIR_REQUEST = 'app/AdminManageUsersPage/CREATE_KEY_PAIR_REQUEST';
export const CREATE_KEY_PAIR_SUCCESS = 'app/AdminManageUsersPage/CREATE_KEY_PAIR_SUCCESS';
export const CREATE_KEY_PAIR_ERROR = 'app/AdminManageUsersPage/CREATE_KEY_PAIR_ERROR';

export const DELETE_KEY_PAIR_REQUEST = 'app/AdminManageUsersPage/DELETE_KEY_PAIR_REQUEST';
export const DELETE_KEY_PAIR_SUCCESS = 'app/AdminManageUsersPage/DELETE_KEY_PAIR_SUCCESS';
export const DELETE_KEY_PAIR_ERROR = 'app/AdminManageUsersPage/DELETE_KEY_PAIR_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchUsersInProgress: false,
  fetchUsersError: null,
  users: [],
  keyPairUserId: null,
  openKeyPairInProgress: null,
  openKeyPairError: null,
  createKeyPairInProgress: null,
  createKeyPairError: null,
  deleteKeyPairInProgress: null,
  deleteKeyPairError: null,
};

const updateUserAttributes = ( users, user ) => {
  const updatedUsers = [];
  let userIndex = -1;

  users.forEach(( curUser, index ) => {
    if( user.id !== curUser.id ){
      updatedUsers.push( curUser );
    } else {
      userIndex = index;
    }
  });

  if( userIndex === -1 ) {
    updatedUsers.push(user);
  } else {
    updatedUsers.splice( userIndex, 0, user );
  }

  return updatedUsers;
};

const adminManageUsersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        pagination: payload.meta,
        fetchUsersInProgress: false,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchUsersInProgress: false, fetchUsersError: payload };

    case OPEN_KEY_PAIR_REQUEST:
      return {
        ...state,
        keyPairUserId: payload.userId,
        openKeyPairInProgress: true,
        openKeyPairError: null,
      };
    case OPEN_KEY_PAIR_SUCCESS:
      return {
        users: updateUserAttributes( state.users, payload.user ),
        keyPairUserId: null,
        openKeyPairInProgress: false,
      };
    case OPEN_KEY_PAIR_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        keyPairUserId: null,
        openKeyPairInProgress: false,
        openKeyPairError: {
          keyPairUserId: state.keyPairUserId,
          error: payload,
        },
      };
    }

    case CREATE_KEY_PAIR_REQUEST:
      return {
        ...state,
        keyPairUserId: payload.userId,
        createKeyPairInProgress: true,
        createKeyPairError: null,
      };
    case CREATE_KEY_PAIR_SUCCESS:
      return {
        users: updateUserAttributes( state.users, payload.user ),
        keyPairUserId: null,
        createKeyPairInProgress: false,
      };
    case CREATE_KEY_PAIR_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        keyPairUserId: null,
        createKeyPairInProgress: false,
        createKeyPairError: {
          keyPairUserId: state.keyPairUserId,
          error: payload,
        },
      };
    }

    case DELETE_KEY_PAIR_REQUEST:
      return {
        ...state,
        keyPairUserId: payload.userId,
        deleteKeyPairInProgress: true,
        deleteKeyPairError: null,
      };
    case DELETE_KEY_PAIR_SUCCESS:
      return {
        users: updateUserAttributes( state.users, payload.user ),
        keyPairUserId: null,
        deleteKeyPairInProgress: false,
      };
    case DELETE_KEY_PAIR_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        keyPairUserId: null,
        deleteKeyPairInProgress: false,
        deleteKeyPairError: {
          keyPairUserId: state.keyPairUserId,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default adminManageUsersPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
  payload: {},
});

export const fetchUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: response,
});

export const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

export const openKeyPairRequest = userId => ({
  type: OPEN_KEY_PAIR_REQUEST,
  payload: { userId },
});

export const openKeyPairSuccess = response => ({
  type: OPEN_KEY_PAIR_SUCCESS,
  payload: response,
});

export const openKeyPairError = e => ({
  type: OPEN_KEY_PAIR_ERROR,
  error: true,
  payload: e,
});

export const createKeyPairRequest = userId => ({
  type: CREATE_KEY_PAIR_REQUEST,
  payload: { userId },
});

export const createKeyPairSuccess = response => ({
  type: CREATE_KEY_PAIR_SUCCESS,
  payload: response,
});

export const createKeyPairError = e => ({
  type: CREATE_KEY_PAIR_ERROR,
  error: true,
  payload: e,
});

export const deleteKeyPairRequest = userId => ({
  type: DELETE_KEY_PAIR_REQUEST,
  payload: { userId },
});

export const deleteKeyPairSuccess = response => ({
  type: DELETE_KEY_PAIR_SUCCESS,
  payload: response,
});

export const deleteKeyPairError = e => ({
  type: DELETE_KEY_PAIR_ERROR,
  error: true,
  payload: e,
});


export const fetchUsers = page => (dispatch, getState, sdk) => {
  dispatch( fetchUsersRequest());

  return adminFetchBusinessUsers( page )
    .then( response => {
      dispatch( fetchUsersSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch( fetchUsersError( storableError(e)));

      throw e;
    });
};

export const openKeyPair = userId => (dispatch, getState, sdk) => {
  dispatch( openKeyPairRequest({ userId }));

  return adminOpenApiKeyPair( userId )
    .then(response => {
      dispatch( openKeyPairSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch(openKeyPairError( storableError(e)));
    });
};

export const createKeyPair = userId => (dispatch, getState, sdk) => {
  dispatch( createKeyPairRequest({ userId }));

  return adminCreateApiKeyPair( userId )
    .then(response => {
      dispatch( createKeyPairSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch( createKeyPairError( storableError(e)));
    });
};

export const deleteKeyPair = userId => (dispatch, getState, sdk) => {
  dispatch( deleteKeyPairRequest({ userId }));

  return adminDeleteApiKeyPair( userId )
    .then(response => {
      dispatch( deleteKeyPairSuccess( response ));

      return response;
    })
    .catch(e => {
      dispatch( deleteKeyPairError( storableError(e)));
    });
};
