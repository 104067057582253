import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  updateProfile,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './ManageCouponsPage.css';

const NEW_COUPON_CODE_INDICATOR = '000000000000000000000000000000';

const convertToTableData = coupons => {
  if( typeof coupons === 'object') {
    return Object.keys( coupons ).map( code => {
      const coupon = { ...coupons[ code ]};
      coupon.created_at = ( new Date( coupon.created_at )).toDateString();

      return { code, ...coupon };
    })
  }

  return [];
};

export class ManageCouponsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      updateInProgress,
      updateProfileError,
      scrollingDisabled,
      intl,
      onUpdateProfile,
    } = this.props;

    const isMainAdmin = !!( currentUser?.attributes?.profile?.metadata?.isAdmin &&
      currentUser?.id.uuid === process.env.REACT_APP_MAIN_ADMIN_ID );

    if( !isMainAdmin ){
      return <NamedRedirect name="LandingPage" />;
    }

    const columns = [
      {
        name: <div style={{ fontSize: '16px'}}>Code</div>,
        selector: 'code',
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Title</div>,
        selector: 'title',
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Description</div>,
        selector: 'description',
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Percentage</div>,
        selector: 'percentage',
        center: true,
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Fixed Amount</div>,
        selector: 'fixed_amount',
        center: true,
        sortable: true,
        style: { fontSize: '16px' },
      },
      {
        name: <div style={{ fontSize: '16px'}}>Created At</div>,
        selector: 'created_at',
        sortable: false,
        style: { fontSize: '16px' },
      },
      {
        name: '',
        selector: 'edit',
        sortable: false,
        cell: row => (
          <NamedLink className={css.editCell} name="EditCouponPage" params={{ code: row.code }}>
            Edit
          </NamedLink>
        ),
      },
      {
        name: '',
        selector: 'delete',
        sortable: false,
        cell: row => (
          <div
            className={css.deleteCell}
            onClick={() => {
              Swal.fire({
                title: 'Are you sure you want to delete this coupon?',
                text: 'You will not be able to recover this coupon after deleting it!',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
              }).then( result => {
                if (result.isConfirmed) {
                  const coupons = currentUser?.attributes?.profile?.publicData?.coupons || {};

                  delete coupons[row.code];
                  onUpdateProfile({ publicData: { coupons } });
                }
              })
            }}
          >
            Delete
          </div>
        ),
      }
    ];
    const data = currentUser?.attributes?.profile?.publicData?.coupons ?
      convertToTableData( currentUser?.attributes?.profile?.publicData?.coupons ) : [];
    const title = intl.formatMessage({ id: 'ManageCouponsPage.title' });

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageCouponsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.couponPanel}>
              <div className={css.panelHeading}>
                <div>{title}</div>
                <div className={css.editCouponLinkBlock}>
                  <NamedLink
                    className={css.link}
                    name="EditCouponPage"
                    params={{ code: NEW_COUPON_CODE_INDICATOR }}
                  >
                    <FormattedMessage id="ManageCouponsPage.newCouponLink" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.dataTableWrapper}>
                <DataTable
                  style={{ fontSize: '16px!important'}}
                  keyField={'code'}
                  defaultSortField={'code'}
                  striped={true}
                  highlightOnHover={true}
                  columns={columns}
                  data={data}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageCouponsPageComponent.defaultProps = {
  updateProfileError: null,
};

const { bool, func } = PropTypes;

ManageCouponsPageComponent.propTypes = {
  onUpdateProfile: func.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;

  return {
    currentUser,
    updateInProgress,
    updateProfileError,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: actionPayload => dispatch(updateProfile(actionPayload)),
});

const ManageCouponsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageCouponsPageComponent);

export default ManageCouponsPage;
