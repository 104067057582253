import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { parse } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
} from '../../components';
import { SubscriptionForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { finalizeStripeSubscription } from './SubscriptionPage.duck';
import css from './SubscriptionPage.css';
import config from '../../config';
import qs from 'qs';
import { apiBaseUrl } from '../../util/api';

const createStripeSession = (lineItems, mode, successUrl, cancelUrl) => {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ lineItems, mode, successUrl, cancelUrl }),
    url: `${apiBaseUrl()}/api/create-stripe-session`,
  };

  return axios(options);
};

const submitInitializeStripeSubscription = values => {
  const priceId = values.priceId;

  const priceIdsToStateCodes = {};
  priceIdsToStateCodes[process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID] =
    process.env.REACT_APP_STRIPE_MONTHLY_SCKEY;
  priceIdsToStateCodes[process.env.REACT_APP_STRIPE_QUARTERLY_PRICE_ID] =
    process.env.REACT_APP_STRIPE_QUARTERLY_SCKEY;
  priceIdsToStateCodes[process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID] =
    process.env.REACT_APP_STRIPE_ANNUAL_SCKEY;

  const successPath = pathByRouteName("SubscriptionPage", routeConfiguration());
  const successUrl =
    `${process.env.REACT_APP_CANONICAL_ROOT_URL}${successPath}?stripe_subscription_key=${priceIdsToStateCodes[priceId]}`;
  const cancelPath = pathByRouteName("SubscriptionPage", routeConfiguration());
  const cancelUrl = `${process.env.REACT_APP_CANONICAL_ROOT_URL}${cancelPath}`;

  const stripe = window.Stripe(config.stripe.publishableKey);

  createStripeSession(
    JSON.stringify([{price: priceId, quantity: 1}]),
    'subscription',
    successUrl,
    cancelUrl
  )
  .then(result => {
    return stripe.redirectToCheckout({ sessionId: result.data.sessionId });
  })
  .catch(error => {
    console.error(`Failed to redirect to Stripe Checkout : ${error.message}`);
  });
};

export const SubscriptionPageComponent = props => {
  const {
    stripeSubscriptionError,
    stripeSubscriptionInProgress,
    currentUser,
    scrollingDisabled,
    onFinalizeStripeSubscription,
    intl,
    history,
    location,
  } = props;

  const [finalizingSubscription, setFinalizingSubscription] = useState(false);

  const submitFinalizeStripeSubscription = (finalizeUserFunc, stateCode, history) => {
    const validStateCodes = [
      process.env.REACT_APP_STRIPE_MONTHLY_SCKEY,
      process.env.REACT_APP_STRIPE_QUARTERLY_SCKEY,
      process.env.REACT_APP_STRIPE_ANNUAL_SCKEY
    ];

    if(validStateCodes.includes(stateCode)) {
      finalizeUserFunc(stateCode).then(() => {
        setFinalizingSubscription(false);
        history.push(createResourceLocatorString('SubscriptionPage', routeConfiguration()));
      })
    } else {
      setFinalizingSubscription(false);
      console.error('Unknown state code returned from Stripe Checkout : ' + stateCode);
      history.push(createResourceLocatorString('SubscriptionPage', routeConfiguration()));
    }
  };

  const title = intl.formatMessage({ id: 'SubscriptionPage.title' });

  const urlParams = parse(location.search) || {};

  if(urlParams.stripe_subscription_key && !finalizingSubscription){
    setFinalizingSubscription(true);

    submitFinalizeStripeSubscription(onFinalizeStripeSubscription, urlParams.stripe_subscription_key, history);
  }

  if(finalizingSubscription){
    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        Finalizing Stripe subscription...
      </Page>
    );
  }

  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const stripeSubscriptionKey = privateData.stripeSubscriptionKey;
  const subscriptionForm = !stripeSubscriptionKey ? (
    <SubscriptionForm
      className={css.form}
      stripeSubscriptionError={stripeSubscriptionError}
      currentUser={currentUser}
      onSubmit={submitInitializeStripeSubscription}
      inProgress={stripeSubscriptionInProgress}
      ready={true}
      intl={intl}
    />
  ) : (
    <>
      You already have an active Resaleforce subscription.
      If you want to unsubscribe please contact Resaleforce support.
    </>
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="SubscriptionPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="SubscriptionPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="SubscriptionPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="SubscriptionPage.heading" />
            </h1>
            {subscriptionForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

SubscriptionPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

SubscriptionPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser} = state.user;
  const {
    stripeSubscriptionError,
    stripeSubscriptionInProgress,
  } = state.SubscriptionPage;
  return {
    stripeSubscriptionError,
    stripeSubscriptionInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFinalizeStripeSubscription: (stripeSubscriptionKey) => dispatch(finalizeStripeSubscription(stripeSubscriptionKey)),
});

const SubscriptionPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SubscriptionPageComponent);

SubscriptionPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default SubscriptionPage;
