import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  NamedRedirect,
  Page,
} from '../../components';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { finalizeShippoIntegration } from './ShippingDetailsPage.duck';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const submitFinalizeShippoIntegration = (finalizeUserFunc, shippoCode, history) => {
  finalizeUserFunc(shippoCode).then(() => {
    history.push(createResourceLocatorString('ShippingDetailsPage', routeConfiguration()));
  })
};

export const ShippingCallbackPageComponent = props => {
  const {
    shippoIntegrationError,
    currentUser,
    scrollingDisabled,
    onFinalizeShippoIntegration,
    intl,
    history,
    location,
  } = props;

  const [finInProgress, setFinInProgress] = useState(false);

  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const shippoAccessToken = privateData.shippoAccessToken;
  if(shippoAccessToken){
    return <NamedRedirect name="ShippingDetailsPage" />;
  }

  const { state, code } = parse(location.search);
  if(!state || !code){
    return <NamedRedirect name="ShippingDetailsPage" />;
  }

  const shippoIntegrationState = privateData.shippoIntegrationState;
  if(shippoIntegrationState && state !== shippoIntegrationState){
    console.error('Shippo integration state returned within callback URL does not match initial state value.');
    return <NamedRedirect name="ShippingDetailsPage" />;
  }

  if(shippoIntegrationError){
    console.error('Error finalizing Shippo integration process: ' + shippoIntegrationError.message);
    return <NamedRedirect name="ShippingDetailsPage" />;
  }

  if(shippoIntegrationState && !finInProgress){
    setFinInProgress(true);
    submitFinalizeShippoIntegration(onFinalizeShippoIntegration, code, history);
  }

  const title = intl.formatMessage({ id: 'ShippingDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      Finalizing Shippo integration...
    </Page>
  );
};

ShippingCallbackPageComponent.defaultProps = {
  shippoIntegrationError: null,
  shippoIntegrationInProgress: null,
  currentUser: null,
  scrollingDisabled: true,
};

const { bool, func } = PropTypes;

ShippingCallbackPageComponent.propTypes = {
  shippoIntegrationError: propTypes.error,
  shippoIntegrationInProgress: bool,
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  onFinalizeShippoIntegration: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser} = state.user;
  const {
    shippoIntegrationError,
    shippoIntegrationInProgress,
  } = state.ShippingDetailsPage;
  return {
    shippoIntegrationError,
    shippoIntegrationInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFinalizeShippoIntegration: (shippoCode) => dispatch(finalizeShippoIntegration(shippoCode)),
});

const ShippingCallbackPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ShippingCallbackPageComponent);

ShippingCallbackPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default ShippingCallbackPage;
