import axios from 'axios';
import qs from 'qs';
import { apiBaseUrl } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/SendInvitePage/SET_INITIAL_STATE';

export const SEND_INVITE_REQUEST = 'app/SendInvitePage/SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'app/SendInvitePage/SEND_INVITE_SUCCESS';
export const SEND_INVITE_ERROR = 'app/SendInvitePage/SEND_INVITE_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendInviteInProgress: false,
  sendInviteSuccess: false,
  sendInviteError: null,
};

export default function sendInvitePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case SEND_INVITE_REQUEST:
      return { ...state, sendInviteInProgress: true, sendInviteSuccess: false, sendInviteError: null };
    case SEND_INVITE_SUCCESS:
      return { ...state, sendInviteInProgress: false, sendInviteSuccess: true, };
    case SEND_INVITE_ERROR:
      return { ...state, sendInviteSuccess: false, sendInviteInProgress: false, sendInviteError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const sendInviteRequest = () => ({
  type: SEND_INVITE_REQUEST,
});

export const sendInviteSuccess = () => ({
  type: SEND_INVITE_SUCCESS,
});

export const sendInviteError = e => ({
  type: SEND_INVITE_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const sendInvite = recipientEMail => (dispatch) => {
  dispatch(sendInviteRequest());

  const data = { recipientEMail };

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: `${apiBaseUrl()}/api/send-invite`,
  };

  return axios(options)
  .then( result => {
    dispatch(sendInviteSuccess());
    console.log('Successfully sent invite');
    return result;
  })
  .catch( error => {
    dispatch(sendInviteError(error.message));
    console.error('Sending invite failed with an error: ' + error.message);
    return error;
  });
};

export const loadData = () => (dispatch) => {
  dispatch(setInitialState());

  return Promise.resolve(true);
};
