import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton } from '../../components';

import css from './ShippingDetailsForm.css';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

class ShippingDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showVerificationEmailSentMessage: false };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            shippoIntegrationError,
            currentUser,
            handleSubmit,
            inProgress,
            invalid,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          // Shippo integration error
          const shippoIntegrationErrorMessage = shippoIntegrationError ? (
            <span className={css.error}>
              <FormattedMessage id="ShippingDetailsForm.genericFailure" />
            </span>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                In order to start shipping you need to follow the integration procedure with our
                shipping partner - Shippo, by pressing the button below.
              </div>
              <div className={css.bottomWrapper}>
                {shippoIntegrationErrorMessage}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={false}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ShippingDetailsForm.startIntegration" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ShippingDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

const { bool, func, string } = PropTypes;

ShippingDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const ShippingDetailsForm = compose(injectIntl)(ShippingDetailsFormComponent);

ShippingDetailsForm.displayName = 'ShippingDetailsForm';

export default ShippingDetailsForm;
