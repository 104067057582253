import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './FaqPage.css';

const FaqPage = () => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle,
    siteLinkedInPage, siteYouTubeChannel
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Frequently Asked Questions"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description: 'Resaleforce Frequently Asked Questions',
        name: 'FAQ page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}><b>How Resaleforce Works</b></h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h2 style={{textDecoration: 'underline', fontWeight: 'bold', color: 'red'}}>
                <br/>FAQs
              </h2>

              <p>
                <b>AUTHENTICATION HOW DOES IT WORK?</b><br/>
                Our diverse team authenticates the shoes by identifying any flaws
                as well as originality. We authenticate based on pictures that
                are uploaded by either the seller or requestor. However, Resaleforce
                does not control any transactions outside the platform.
              </p>

              <p>
                <b>IS MY INFORMATION SAFE?</b><br/>
                Yes, your information is secured.
              </p>
              <p>
                <b>IS MY PAYMENT INFORMATION SAVED ON THIS PLATFORM?</b><br/>
                No. Resaleforce does not store your credit/debit card information.
              </p>
              <p>
                <b>WHAT IS THE PRICING?</b><br/>
                For business pricing information, please contact
                <ExternalLink
                  key="supportLink"
                  href='mailto:hello@resaleforce.com'
                  title={'Support'}
                >
                  hello@resaleforce.com
                </ExternalLink>
              </p>
              <p>
                <b>Transaction outside of the marketplace is at your own risk.</b><br/>
                Have a question or feedback?&nbsp;
                Contact us at hello@resaleforce.com
                <br/>
                Resale Force by Ownkicks, Inc, Seymour, CT 06483
                <br/>
                Google Voice:&nbsp;
                <ExternalLink
                  key="phoneLink"
                  href={'tel:203-941-0470'}
                  title={'Phone'}
                >
                  203-941-0470
                </ExternalLink>
                &nbsp;(Text or Call)<br/>
              </p>
              <p>
                Disclaimer: Authentication results are based on information submitted
                and at the time of review. It is your full responsibility to verify
                the certificate of authenticity issued by emailing hello@resaleforce.com.
                We will not be held liable for any transactions outside of Resaleforce’ platform.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FaqPage;
