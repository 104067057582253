import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Logo,
  NamedLink,
} from '../../components';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    rootClassName,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool,
  onLogout: func,
  notificationCount: number,
  onSearchSubmit: func,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
