import { storableError } from '../util/errors';
import { fetchCurrentUser } from './user.duck';
import { sendAuthenticationDemoRequest } from '../util/api';

// ================ Action types ================ //

export const REQUIRE_AUTHENTICATION_DEMO_REQUEST = 'app/Authentication/REQUIRE_AUTHENTICATION_DEMO_REQUEST';
export const REQUIRE_AUTHENTICATION_DEMO_SUCCESS = 'app/Authentication/REQUIRE_AUTHENTICATION_DEMO_SUCCESS';
export const REQUIRE_AUTHENTICATION_DEMO_ERROR = 'app/Authentication/REQUIRE_AUTHENTICATION_DEMO_ERROR';

// ================ Reducer ================ //

const initialState = {
  isDemoRequestSent: false,

  // Demo request
  demoRequestError: null,
  demoRequestInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case REQUIRE_AUTHENTICATION_DEMO_REQUEST:
      return {
        ...state,
        demoRequestInProgress: true,
        demoRequestError: null,
      };
    case REQUIRE_AUTHENTICATION_DEMO_SUCCESS:
      return { ...state, demoRequestInProgress: false, isDemoRequestSent: true };
    case REQUIRE_AUTHENTICATION_DEMO_ERROR:
      return { ...state, demoRequestInProgress: false, demoRequestError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const demoRequestInProgress = state => {
  return state.Authentication.demoRequestInProgress;
};

// ================ Action creators ================ //

export const requireAuthenticationDemoRequest = () => ({
  type: REQUIRE_AUTHENTICATION_DEMO_REQUEST
});
export const requireAuthenticationDemoSuccess = () => ({
  type: REQUIRE_AUTHENTICATION_DEMO_SUCCESS
});
export const requireAuthenticationDemoError = error => ({
  type: REQUIRE_AUTHENTICATION_DEMO_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const requireAuthenticationDemo = data => (dispatch, getState, sdk) => {
  if(demoRequestInProgress(getState())) {
    return Promise.reject(new Error('Authentication demo request is already in progress'));
  }
  dispatch(requireAuthenticationDemoRequest());

  return sendAuthenticationDemoRequest( data )
    .then(() => dispatch(requireAuthenticationDemoSuccess()))
    .catch(e => {
      dispatch(requireAuthenticationDemoError(storableError(e)));

      throw e;
    });
};
