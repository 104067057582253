import React from 'react';
import { shape, string, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const DeliveryOptionsMaybe = props => {
  const { publicData } = props;
  const delivery = (publicData && publicData.delivery) || [];

  const shippingOption = delivery.includes('shipping') ? (
    <p className={css.deliveryOption}>- Shipping</p>
  ) : null;
  const pickupOption = delivery.includes('pickup') ? (
    <p className={css.deliveryOption}>- Pick Up</p>
  ) : null;

  return publicData && publicData.delivery ? (
    <div className={css.sectionShipping}>
      <h2 className={css.deliveryTitle}>
        <FormattedMessage id="ListingPage.deliveryTitle" />
      </h2>
      {shippingOption}
      {pickupOption}
    </div>
  ) : null;
};

DeliveryOptionsMaybe.defaultProps = { className: null, rootClassName: null };

DeliveryOptionsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    delivery: array,
  }),
};

export default DeliveryOptionsMaybe;
