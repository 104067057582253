import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../components';

import css from './SignupForm.css';
import { passwordConfirmationMatch } from '../../util/validators';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        values,
        invalid,
        intl,
        onOpenTermsOfService,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // password confirmation
      const passwordConfirmationLabel = intl.formatMessage({
        id: 'SignupForm.passwordConfirmationLabel',
      });
      const passwordConfirmationPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordConfirmationPlaceholder',
      });
      const passwordConfirmationMatchMessage = intl.formatMessage({
        id: 'SignupForm.passwordShouldMatch',
      });
      const passwordShouldMatch = validators.passwordConfirmationMatch(passwordConfirmationMatchMessage, values.password);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // accountType
      const accountTypeRequiredMessage = intl.formatMessage({
        id: 'SignupForm.accountTypeRequired',
      });
      const accountTypeRequired = validators.required(accountTypeRequiredMessage);

      // Field for individual account type
      // username
      const usernameLabel = intl.formatMessage({
        id: 'SignupForm.usernameLabel',
      });
      const usernamePlaceholder = intl.formatMessage({
        id: 'SignupForm.usernamePlaceholder',
      });
      const usernameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.usernameRequired',
      });
      const usernameRequired = validators.required(usernameRequiredMessage);

      const individualFields = values.accountType === 'individual' ? (
        <FieldTextInput
          className={css.username}
          type="text"
          id={formId ? `${formId}.username` : 'username'}
          name="username"
          autoComplete="username"
          label={usernameLabel}
          placeholder={usernamePlaceholder}
          validate={usernameRequired}
        />
      ) : null;

      // Fields for business account type
      // storeName
      const storeNameLabel = intl.formatMessage({
        id: 'SignupForm.storeNameLabel',
      });
      const storeNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.storeNamePlaceholder',
      });
      const storeNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.storeNameRequired',
      });
      const storeNameRequired = validators.required(storeNameRequiredMessage);

      // city
      const cityLabel = intl.formatMessage({
        id: 'SignupForm.cityLabel',
      });
      const cityPlaceholder = intl.formatMessage({
        id: 'SignupForm.cityPlaceholder',
      });
      const cityRequiredMessage = intl.formatMessage({
        id: 'SignupForm.cityRequired',
      });
      const cityRequired = validators.required(cityRequiredMessage);

      // state
      const stateLabel = intl.formatMessage({
        id: 'SignupForm.stateLabel',
      });
      const statePlaceholder = intl.formatMessage({
        id: 'SignupForm.statePlaceholder',
      });
      const stateRequiredMessage = intl.formatMessage({
        id: 'SignupForm.stateRequired',
      });
      const stateRequired = validators.required(stateRequiredMessage);

      // tax ID
      const taxIdLabel = intl.formatMessage({
        id: 'SignupForm.taxIdLabel',
      });
      const taxIdPlaceholder = intl.formatMessage({
        id: 'SignupForm.taxIdPlaceholder',
      });
      const taxIdRequiredMessage = intl.formatMessage({
        id: 'SignupForm.taxIdRequired',
      });
      const taxIdRequired = validators.required(taxIdRequiredMessage);

      // website
      const websiteLabel = intl.formatMessage({
        id: 'SignupForm.websiteLabel',
      });
      const websitePlaceholder = intl.formatMessage({
        id: 'SignupForm.websitePlaceholder',
      });

      const businessFields = values.accountType === 'business' ? (
        <>
          <FieldTextInput
            className={css.storeName}
            type="text"
            id={formId ? `${formId}.storeName` : 'storeName'}
            name="storeName"
            label={storeNameLabel}
            placeholder={storeNamePlaceholder}
            validate={storeNameRequired}
          />
          <div className={css.location}>
            <FieldTextInput
              className={css.cityRoot}
              type="text"
              id={formId ? `${formId}.city` : 'city'}
              name="city"
              autoComplete="address-level1"
              label={cityLabel}
              placeholder={cityPlaceholder}
              validate={cityRequired}
            />
            <FieldTextInput
              className={css.stateRoot}
              type="text"
              id={formId ? `${formId}.state` : 'state'}
              name="state"
              autoComplete="address-level2"
              label={stateLabel}
              placeholder={statePlaceholder}
              validate={stateRequired}
            />
          </div>
          <FieldTextInput
            className={css.taxId}
            type="text"
            id={formId ? `${formId}.taxId` : 'taxId'}
            name="taxId"
            label={taxIdLabel}
            placeholder={taxIdPlaceholder}
            validate={taxIdRequired}
          />
          <FieldTextInput
            className={css.website}
            type="text"
            id={formId ? `${formId}.website` : 'website'}
            name="website"
            label={websiteLabel}
            placeholder={websitePlaceholder}
          />
        </>
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="hidden"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.passwordConfirmation` : 'passwordConfirmation'}
              name="passwordConfirmation"
              autoComplete="new-password"
              label={passwordConfirmationLabel}
              placeholder={passwordConfirmationPlaceholder}
              validate={passwordShouldMatch}
            />
            <FieldSelect
              className={css.accountType}
              id={formId ? `${formId}.accountType` : 'accountType'}
              name="accountType" label="Choose account type:" validate={accountTypeRequired}>
              <option value="">Account type...</option>
              <option value="business">Business</option>
            </FieldSelect>
            {individualFields}
            {businessFields}
          </div>


          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
