import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 0h-9.333c-1.841 0-3.333 1.493-3.333 3.333v9.333c0 1.841 1.493 3.333 3.333 3.333h9.333c1.841 0 3.333-1.493 3.333-3.333v-9.333c0-1.841-1.492-3.333-3.333-3.333zm-7.333 12.667h-2v-7.333h2v7.333zm-1-8.179c-.644 0-1.167-.527-1.167-1.176s.523-1.176 1.167-1.176 1.167.527 1.167 1.176-.522 1.176-1.167 1.176zm9 8.179h-2v-3.736c0-2.245-2.667-2.075-2.667 0v3.736h-2v-7.333h2v1.177c.931-1.724 4.667-1.851 4.667 1.651v4.506z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
