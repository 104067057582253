import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { SendInviteForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { sendInvite, loadData } from './SendInvitePage.duck';

import css from './SendInvitePage.css';

export class SendInvitePageComponent extends Component {
  render() {
    const {
      currentUser,
      intl,
      isAuthenticated,
      scrollingDisabled,
      sendInviteInProgress,
      sendInviteSuccess,
      sendInviteError,
      onSendInvite,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;
    const isAdmin = currentUserLoaded ? !!currentUser.attributes.profile.metadata.isAdmin : false;

    // If the user is not authenticated or is not an admin, redirect to the landing page
    if (!isAuthenticated || !isAdmin) {
      return <NamedRedirect name="LandingPage" />;
    }

    const sendInviteSuccessMessage = sendInviteSuccess ? (
      <div className={css.success}>
        <FormattedMessage
          id="SendInvitePage.sendInviteSuccess"
        />
      </div>
    ) : null;

    const sendInviteErrorMessage = sendInviteError ? (
      <div className={css.error}>
        <FormattedMessage
          id="SendInvitePage.sendInviteFailed"
          values={{errorMessage: sendInviteError}}
        />
      </div>
    ) : null;

    const handleSubmitInvite = values => {
      const { recipientEMail } = values;

      onSendInvite(recipientEMail.trim());
    };

    const formContent = (
      <div className={css.content}>
        {sendInviteSuccessMessage}
        {sendInviteErrorMessage}
        <SendInviteForm
          className={css.form}
          onSubmit={handleSubmitInvite}
          inProgress={sendInviteInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
        />
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'SendInvitePage.schemaTitleInvite' }, { siteTitle });

    const topbarClasses = classNames({});

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer className={topbarClasses} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              {formContent}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SendInvitePageComponent.defaultProps = {
  sendInviteError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool, string, func } = PropTypes;

SendInvitePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  sendInviteError: string,
  scrollingDisabled: bool.isRequired,

  sendInviteInProgress: bool.isRequired,
  onSendInvite: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { sendInviteInProgress, sendInviteSuccess, sendInviteError } = state.SendInvitePage;
  return {
    currentUser,
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    sendInviteInProgress,
    sendInviteSuccess,
    sendInviteError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendInvite: (recipientEMail) => dispatch(sendInvite(recipientEMail)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SendInvitePage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SendInvitePageComponent);

SendInvitePage.loadData = () => {
  return loadData();
};

export default SendInvitePage;
