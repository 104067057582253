import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';
import { stringify } from '../../util/urlHelpers';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, brand, condition, shoeSize, referenceNumber } = props;
  const to = { search: stringify({referenceNumber}) };

  if(referenceNumber){
    return (
      <div>
        Reference number:
        <NamedLink name="AuthenticationTrackingPage" to={to} target="_blank">&nbsp;
          <b>{referenceNumber}</b>
        </NamedLink>
      </div>
    );
  }

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <div className={css.attributeBlocks}>
        {brand}
        {condition}
        {shoeSize}
      </div>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
