import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './AboutPage.css';
import image from './about-us-1056.jpg';

const AboutPage = () => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle,
          siteLinkedInPage, founderLinkedInPage
        } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Resaleforce',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Our Story</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>
                Research shows that fake shoes are the most counterfeited products
                that enter the United States. $1.2 billion worth of counterfeit
                sneakers are sold yearly. It is easy to get ripped off when buying
                shoes online. My personal experiences show that it's difficult to have
                faith in knowing who you can trust and not get scammed. This led
                to the creation of Own Kicks as a better way to avoid buying fake
                sneakers and streetwear.
              </p>

              <p>
                When authenticity matters, it's Own Kicks.
              </p>

              <p>
                <ExternalLink
                  key="linkToLinkedIn"
                  href={siteLinkedInPage}
                  title={'LinkedIn'}
                >
                  LinkedIn
                </ExternalLink>
                <br/>
                Founder:&nbsp;&nbsp;
                <ExternalLink
                  className={css.founderLink}
                  key="founderLinkToLinkedIn"
                  href={founderLinkedInPage}
                  title={'Founder'}
                >
                  Lance Troh
                </ExternalLink>
              </p>

              <p id="contact">
                Resale Force by Ownkicks, Inc, Seymour, CT 06483
                <br/>
                Google Voice:&nbsp;
                <ExternalLink
                  key="phoneLink"
                  href={'tel:203-941-0470'}
                  title={'Phone'}
                >
                  203-941-0470
                </ExternalLink>
                &nbsp;(Text or Call)<br/>
                <ExternalLink
                  key="emailLink"
                  href={'mailto:hello@resaleforce.com'}
                  title={'EMail'}
                >
                  hello@resaleforce.com
                </ExternalLink>
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
