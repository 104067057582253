import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'New York City, New York, United States',

      bounds: new LatLngBounds(new LatLng(40.917576401307, -73.7008392055224), new LatLng(40.477399, -74.2590879797556)),
    },
  },
  {
    id: 'default-boston',
    predictionPlace: {
      address: 'Boston, Massachusetts, United States',
      bounds: new LatLngBounds(new LatLng(42.3974009328397, -70.9860500028801), new LatLng(42.3196059806256, -71.1255750165112)),
    },
  },
  {
    id: 'default-hartford',
    predictionPlace: {
      address: 'Hartford, Connecticut, United States',
      bounds: new LatLngBounds(new LatLng(41.9252009309504, -72.6425340158432), new LatLng(41.7158490690496, -72.78379001)),
    },
  },
];
